import { pathTracker, apiAccessToken } from './services';
import { IS_SERVER } from './Environment';
import Poller from './poller';

class DeveloperSession extends Poller {
  constructor(pollTimeout, authServer, pubSub, browserHistory) {
    super(pollTimeout);
    this.authServer = authServer;
    this.pubSub = pubSub;
    this.browserHistory = browserHistory;
    this.loggedIn = false;
  }

  sendPoll() {
    return this.authServer.me().then(() => {
      if (this.loggedIn !== true) {
        this._afterLogin();
      }
    }).catch(() => {
      if (this.loggedIn !== false) {
        this._afterLogout();
      } else {
        this.stopPolling();
      }
    });
  }

  checkLoggedIn() {
    if (IS_SERVER) {
      return Promise.resolve(false);
    }

    return this.authServer.me().then(() => true).catch(() => false);
  }

  login(email, password) {
    return this.authServer.login(email, password).then(this._afterLogin.bind(this));
  }

  logout() {
    return this.authServer.logout().then(this._handleLogout.bind(this));
  }

  _afterLogin() {
    apiAccessToken.clearCurrentToken();
    this.startPolling();
    this.loggedIn = true;
    this.pubSub.publish('login', true);
  }

  _handleLogout() {
    this._afterLogout();
    // push display only logout page, and restore the previous to cause a reload in the router
    this.browserHistory.push('/logout');
    this.browserHistory.push(pathTracker.getLastPathExcluding('/logout'));
  }

  _afterLogout() {
    this.stopPolling();
    this.loggedIn = false;
    this.pubSub.publish('login', false);
    apiAccessToken.clearCurrentToken();
  }
}

export default DeveloperSession;
