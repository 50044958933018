/* @flow weak */

import React from 'react';
import { Link } from 'react-router';
import { Glyphicon } from 'react-bootstrap';

import NavigationTree from './NavigationTree';
import { getParentSubSection, topLevelSections, walkSection } from '../utils/articles';

const API_RELEASE_NOTES_LINK =
  'https://support.getadministrate.com/hc/en-us/sections/360000068748';

const KNOWN_APIS = ["core", "lms", "weblink"];

function ResourceLink(props, context) {
  const { to, children } = props;
  const { router } = context;

  const linkClassName = router.isActive(to) ? 'active' : '';

  return (
    <Link
      className={`list-group-item ${linkClassName}`}
      to={to}
    >
      { children }
    </Link>
  );
}

ResourceLink.propTypes = {
  to: React.PropTypes.string.isRequired,
  children: React.PropTypes.node.isRequired,
};

ResourceLink.contextTypes = {
  router: React.PropTypes.object,
};

const createSectionBuilder = (section) => function buildSection(item) {
  if (typeof item === "string") {
    // we have a dropdown
    section[item] = {};
    return;
  }

  // check if need to put in dropdown or not
  const dropdownName = getParentSubSection(item.path);;
  if (dropdownName) {
    section[dropdownName][item.title] = item.path;
  } else {
    section[item.title] = item.path;
  }
}

export function buildNavigationTree() {
  const tree = {};

  for (const key of topLevelSections) {
    tree[key] = {};
    walkSection(key, createSectionBuilder(tree[key]));
  }

  return tree;
}

const navigationTree = buildNavigationTree();

function ResourceLinks(props) {
  if (!props.docsSection || !KNOWN_APIS.includes(props.docsSection)) {
    return null;
  }

  return (
    <div>
      <h4>Resources</h4>
      <div className="list-group">
        <ResourceLink to={`/docs/${props.docsSection}/api`}>API Reference</ResourceLink>
        <ResourceLink to={`/docs/${props.docsSection}/deprecation-tracker`}>
          <Glyphicon glyph="exclamation-sign" />
          {' '}
          Upcoming API Changes
        </ResourceLink>
      </div>
    </div>
  );
}

function ReleaseNotesLink() {
  return (
    <div className="list-group bottom-float">
      <div className="release-note-item">
        <hr />
        <a
          className="list-group-item"
          href={API_RELEASE_NOTES_LINK}
          rel="noopener noreferrer"
          target="_blank"
        >
          Release Notes
          <Glyphicon className="nav-glyph" glyph="new-window" />
        </a>
      </div>
    </div>
  );
}

const Navigator = ({ showArticles, docsSection }) => (
  <div className="nav-sidebar">
    {showArticles && navigationTree[docsSection] && (
      <div>
        <h4>Learn</h4>
        <NavigationTree
          items={navigationTree[docsSection]}
          isOpen
        />
      </div>
    )}
    <ResourceLinks docsSection={docsSection} />
    <ReleaseNotesLink />
  </div>);

Navigator.propTypes = {
  showArticles: React.PropTypes.bool.isRequired,
  docsSection: React.PropTypes.string.isRequired
};

export default Navigator;