import React from 'react';

import { Button, NavItem, Nav, NavDropdown, MenuItem, InputGroup } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { pubSub, developerSession } from '../utils/services';


class LoginSignupButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: developerSession.loggedIn,
      buttonSize: props.buttonSize,
    };
    this.loginCallBack = loggedIn => this.login(loggedIn);
    this.logout = this.logout.bind(this);
  }

  login(loggedIn) {
    this.setState({ loggedIn });
  }

  logout() {
    developerSession.logout();
  }

  componentDidMount() {
    developerSession.startPolling();
    pubSub.subscribe('login', this.loginCallBack);
  }

  componentWillUnmount() {
    pubSub.unsubscribe('login', this.loginCallBack);
  }

  render() {
    if (this.props.isNav) {
      return (
        <Nav pullRight>
          {this.getButtons()}
        </Nav>
      );
    }
    return (
      <InputGroup className="login_signup_buttons">
        {this.getButtons()}
      </InputGroup>
    );
  }

  getButtons() {
    if (this.state.loggedIn === false) {
      return this.getNotLoggedInButtons();
    } else if (this.state.loggedIn === true) {
      return this.getNavLoggedInButtons();
    }

    return null;
  }

  getNotLoggedInButtons() {
    return [
      <LinkContainer key="login" to="/login">
        <NavItem className="signup-buttons">
          <Button bsSize={this.state.buttonSize} bsStyle="outline-primary" data-test-id="log-in-button">
            Log in
          </Button>
        </NavItem>
      </LinkContainer>,
      <LinkContainer key="signup" to="/signup">
        <NavItem className="signup-buttons">
          <Button bsSize={this.state.buttonSize} bsStyle="primary">
            Sign up
          </Button>
        </NavItem>
      </LinkContainer>,
    ];
  }

  getNavLoggedInButtons() {
    return (
      <NavDropdown title="My Account" id="navbar_account" data-test-id="my-account-menu">
        <LinkContainer to="/account/profile">
          <MenuItem>Profile</MenuItem>
        </LinkContainer>
        <LinkContainer to="/account/my-apps">
          <MenuItem>My Apps</MenuItem>
        </LinkContainer>
        <MenuItem onClick={this.logout}>Log Out</MenuItem>
      </NavDropdown>
    );
  }
}

export default LoginSignupButtons;

LoginSignupButtons.propTypes = {
  buttonSize: React.PropTypes.string,
  isNav: React.PropTypes.bool,
};

LoginSignupButtons.defaultProps = {
  isNav: false,
};
