import React, { Component } from 'react';
import { FormGroup, ControlLabel, Button, HelpBlock, ProgressBar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { authServer } from '../../utils/services';
import { isValidPassword } from '../../utils/validation';
import DeveloperPage from '../DeveloperPage';
import FormControlPassword from '../forms/FormControlPassword';
import KitAlert from '../../components/KitAlert';


class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      done: false,
      serverError: null,
      errors: [],
      token: this.props.params.token,
      tokenIsValid: null,
    };

    authServer.checkResetPasswordToken(this.state.token).then(() => {
      this.setState({
        tokenIsValid: true,
      });
    }).catch(() => {
      this.setState({
        tokenIsValid: false,
      });
    });

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({
      password: event.target.value,
      serverError: null,
      errors: [],
    });
  }

  handleSubmit(event) {
    if (event) {
      event.preventDefault();
    }

    if (!isValidPassword(this.state.password)) {
      this.setState({ errors: ['Valid password is required'] });
      return;
    }

    authServer.resetPassword(
      this.state.token,
      this.state.password,
    ).then(this.handleSuccess.bind(this)).catch(this.handleError.bind(this));
  }

  handleSuccess() {
    this.setState({
      done: true,
    });
  }

  handleError(error) {
    this.setState({
      serverError: error.message,
    });
  }

  render() {
    return (
      <DeveloperPage dpStyle="dark" title="Password reset">
        <p className="description">
          You recently requested to change your password. You can create a new one here.
        </p>
        {this.getContents()}
      </DeveloperPage>
    );
  }

  getContents() {
    if (this.state.tokenIsValid == null) {
      return this.getWaitingOnCheck();
    } else if (this.state.tokenIsValid === false) {
      return this.getInvalidToken();
    } else if (this.state.done) {
      return this.getDoneMessage();
    }
    return this.getForm();
  }

  getWaitingOnCheck() {
    return (
      <div>
        <p className="description">Checking your password reset token ...</p>
        <ProgressBar active now={100} />
      </div>
    );
  }

  getInvalidToken() {
    return (
      <KitAlert alertType="danger">Your password reset token is invalid or has expired</KitAlert>
    );
  }


  getForm() {
    let alert = null;
    if (this.state.serverError) {
      alert = <KitAlert alertType="danger">{this.state.serverError}</KitAlert>;
    }
    return (
      <form onSubmit={this.handleSubmit} >
        {alert}
        <FormGroup controlId="password" validationState={this.getValidationState()}>
          <ControlLabel>New password</ControlLabel>
          <FormControlPassword name="password" value={this.state.password} onChange={this.handleChange} />
          <HelpBlock>
            Must be at least 8 characters and contain 3 of the 4 following character sets:
            lowercase, uppercase, numbers, and symbols.
          </HelpBlock>
        </FormGroup>
        <Button bsStyle="primary" type="submit">Save new password</Button>
      </form>
    );
  }

  getValidationState() {
    const { errors } = this.state;
    return errors && errors.length ? 'error' : null;
  }

  getDoneMessage() {
    return (
      <div className="doneMessage">
        <p>
          <span className="glyphicon glyphicon-ok-sign glyph-background" ><span className="inner" /></span>
          New password has been saved to your account
        </p>
        <Button bsStyle="outline-secondary" disabled>Password saved</Button>
        <br />
        <LinkContainer to="/login">
          <Button bsStyle="primary" type="submit" className="loginButton">Login</Button>
        </LinkContainer>
      </div>
    );
  }
}

ResetPassword.propTypes = {
  params: React.PropTypes.object.isRequired,
};

export default ResetPassword;
