import React, { Component } from 'react';
import { Grid } from 'react-bootstrap';
import Footer from './Footer';

import NavigationBar from './NavigationBar';

class Layout extends Component {
  static propTypes = {
    children: React.PropTypes.oneOfType([
      React.PropTypes.arrayOf(React.PropTypes.node),
      React.PropTypes.node,
    ]).isRequired,
  };

  render() {
    return (
      <div id="layout">
        <NavigationBar />
        <Grid id="container" fluid>
          {this.props.children}
        </Grid>
        <Footer />
      </div>
    );
  }
}

export default Layout;
