import React from 'react';
import { Link } from 'react-router';
import SchemaSearchEngine from '../utils/SchemaSearchEngine';
import DocsSearchEngine from '../utils/DocsSearchEngine';
import { take, debounce, uniqueId } from 'lodash';
import { IS_BROWSER, IS_TEST } from '../utils/Environment';

function buildSearchEngines() {
  coreSchemaSearchEngine = new SchemaSearchEngine('core');
  lmsSchemaSearchEngine = new SchemaSearchEngine('lms');
  webLinkSchemaSearchEngine = new SchemaSearchEngine('weblink');
  docsSearchEngine = new DocsSearchEngine();
}

let coreSchemaSearchEngine = null;
let lmsSchemaSearchEngine = null;
let webLinkSchemaSearchEngine = null;
let docsSearchEngine = null;

const hasGtag = typeof gtag === 'function';

function isType({ type }) {
  return type === 'type';
}

function isQuery({ type }) {
  return type === 'query';
}

function isMutation({ type }) {
  return type === 'mutation';
}

function isArticle({ category }) {
  return category === 'article';
}

function SearchResults(props) {
  const hasAny = props.results.length > 0;

  if (!hasAny) {
    return null;
  }

  const types = props.results.filter(isType);
  const hasTypes = types.length > 0;

  const queries = props.results.filter(isQuery);
  const hasQueries = queries.length > 0;

  const mutations = props.results.filter(isMutation);
  const hasMutations = mutations.length > 0;

  const articles = props.results.filter(isArticle);
  const hasArticles = articles.length > 0;

  return (
    <div key="search-results-container" className="search-results">

      {hasArticles && (
        <div>
          <li key="article-heading" className="heading">
            Articles
          </li>
          {articles.map((result, idx) => (
            <SearchResult
              key={uniqueId('articles')}
              tabstart={10}
              index={idx}
              collapse={props.collapse}
              result={result}
            />
          ))}
        </div>
      )}

      {hasQueries && (
        <div>
          <li key="query-heading" className="heading">
            Queries
          </li>
          {queries.map((result, idx) => (
            <SearchResult
              key={uniqueId('queries')}
              tabstart={20}
              index={idx}
              collapse={props.collapse}
              result={result}
            />
          ))}
        </div>
      )}

      {hasMutations && (
        <div>
          <li key="mutation-heading" className="heading">
            Mutations
          </li>
          {mutations.map((result, idx) => (
            <SearchResult
              key={uniqueId('mutations')}
              tabstart={30}
              index={idx}
              collapse={props.collapse}
              result={result}
            />
          ))}
        </div>
      )}

      {hasTypes && (
        <div>
          <li key="type-heading" className="heading">
            Types
          </li>
          {types.map((result, idx) => (
            <SearchResult
              key={uniqueId('types')}
              tabstart={40}
              index={idx}
              collapse={props.collapse}
              result={result}
            />
          ))}
        </div>
      )}
    </div>
  );
}

function SearchResult(props) {
  return (
    <li key={props.result.href} className="result" onClick={props.collapse}>
      <Link tabIndex={props.tabstart + props.index} to={props.result.href}>
        {props.result.title}{' '}
        <CategoryPill
          href={props.result.href}
          category={props.result.category}
        />
      </Link>
    </li>
  );
}

function CategoryPill(props) {
  let apiSpace = props.category;
  let displayText = props.category.toUpperCase();

  if (props.category === 'article') {
    displayText = getCategoryDisplayText(props.href.substring(1)); // remove leading slash
    apiSpace = displayText.toLowerCase();
  }

  return (
    <span className={`label label-default pull-right pill ${apiSpace}`}>
      {displayText}
    </span>
  );
}

function getCategoryDisplayText(href) {
  const pieces = href.split('/');
  if (pieces.length < 3) {
    // nothing in the /docs/$API/... area to use
    return 'Docs';
  }

  const foundSection = pieces[1];
  if (foundSection.toLowerCase() === 'guides') {
    return 'Docs';
  }

  return foundSection.toUpperCase();
}

const SEARCH_INPUT_ID = 'search-text-input';

export default class Search extends React.Component {
  static propTypes = {
    collapse: React.PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    if (IS_BROWSER || IS_TEST) {
      buildSearchEngines();
    }
    this.searchQuery = '';
    this.state = {
      status: 'TOO_SHORT',
      isOpen: false,
      results: [],
      searchState: 'NONE',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleGlobalClick = this.handleGlobalClick.bind(this);
    this.handleGlobalKeydown = this.handleGlobalKeydown.bind(this);
    this.addSearchResults = this.addSearchResults.bind(this);
    this.debouncedSearch = debounce(this.search.bind(this), 250);
  }

  componentDidMount() {
    window.addEventListener('click', this.handleGlobalClick);
    window.addEventListener('keydown', this.handleGlobalKeydown);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleGlobalClick);
    window.removeEventListener('keydown', this.handleGlobalKeydown);
  }

  handleGlobalKeydown(event) {
    if (event.key === 'k' && event.metaKey) {
      event.stopPropagation();
      event.preventDefault();
      this.toggleOpen();
      return false;
    }
  }

  toggleOpen() {
    if (!this.state.isOpen) {
      window[SEARCH_INPUT_ID].focus();
    }

    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  handleGlobalClick() {
    this.setState({
      isOpen: false,
    });
  }

  handleClick(event) {
    event.stopPropagation();
    this.setState({
      isOpen: true,
    });
  }

  handleChange(event) {
    this.searchQuery = event.target.value;
    if (this.searchQuery.replaceAll(' ', '').length < 3) {
      this.setState({
        status: 'TOO_SHORT',
        isOpen: true,
        results: [],
      });
      return;
    }

    this.debouncedSearch(this.searchQuery);
  }

  search(query) {
    this.setState({
      results: [],
      searchState: 'LOADING',
    });

    // TODO would like to wrap this to make it more reusable/abstract google away... in a hook!
    if (hasGtag) {
      /* eslint-disable-next-line no-undef */
      gtag('event', 'search', { search_term: query });
    }

    this.addSearchResults(this.searchAll(query));
  }

  addSearchResults(results) {
    const sortedFullResults = results
      .concat(this.state.results)
      .sort((a, b) =>
        a.title.toLowerCase().localeCompare(b.title.toLowerCase()),
      );

    this.setState({
      status: 'RESULTS',
      results: sortedFullResults,
    });
  }

  searchAll(query) {
    const articles = this.searchDocs(query)
    const coreItems = this.searchCore(query);
    const lmsItems = this.searchLms(query);
    const webLinkItems = this.searchWebLink(query);

    this.setState({ searchState: 'LOADED' });

    return articles.concat(coreItems).concat(lmsItems).concat(webLinkItems);
  }

  searchDocs(query) {
    return take(docsSearchEngine.search(query), 5).map(result => {
      result.href = '/' + result.href;
      return result;
    });
  }

  searchCore(query) {
    return take(coreSchemaSearchEngine.search(query), 7);
  }

  searchLms(query) {
    return take(lmsSchemaSearchEngine.search(query), 7);
  }

  searchWebLink(query) {
    return take(webLinkSchemaSearchEngine.search(query), 7);
  }

  render() {
    const resultsDisplay = this.state.isOpen ? 'block' : 'none';
    const hasResults = this.state.results.length > 0;
    const moreChars = this.state.status === 'TOO_SHORT';

    return (
      <div className="navbar-form navbar-right">
        <div className="input-group search-bar">
          <div className="input-group-btn">
            <button
              className="btn btn-default"
              type="submit"
              onClick={this.handleClick}
            >
              <i className="glyphicon glyphicon-search" />
            </button>
          </div>
          <ul
            id="search-results-dropdown"
            className="search dropdown-menu"
            style={{ display: resultsDisplay }}
          >
            {moreChars && (
              <li className="help-message">
                Please type at least 3 characters
              </li>
            )}
            {!moreChars && !hasResults && (
              <li className="help-message">Nothing found</li>
            )}

            <SearchResults
              results={this.state.results}
              collapse={this.props.collapse}
            />
          </ul>
          <input
            id={SEARCH_INPUT_ID}
            tabIndex={9}
            role="search"
            type="text"
            className="form-control"
            placeholder="Search"
            onChange={this.handleChange}
            onClick={this.handleClick}
          />
        </div>
      </div>
    );
  }
}
