import React from 'react';
import CallToAction from "./CallToAction";

export default function LandingPageHeroCard({cardTitle, title, byLine, description, callToAction}) {
    return (
        <div className="heroCard">
            <h4>{cardTitle}</h4>
            <h2>{title}</h2>
            <h3>{byLine}</h3>
            <p>{description}</p>
            {!!callToAction && 
                <CallToAction
                    text={callToAction.text}
                    link={callToAction.link}
                />
            }
        </div>
    );
}

LandingPageHeroCard.propTypes = {
    cardTitle: React.PropTypes.string.isRequired,
    title: React.PropTypes.string.isRequired,
    byLine: React.PropTypes.string.isRequired,
    description: React.PropTypes.string.isRequired,
    callToAction: React.PropTypes.object
};
