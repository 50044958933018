import 'vanilla-cookieconsent';

const hasGtag = typeof gtag === 'function';

export default function createCookieConsentPopup() {
  // obtain plugin
  var cc = initCookieConsent();

  // run plugin with your configuration
  cc.run({
    current_lang: 'en',

    onAccept: function (cookie) {
      if (hasGtag && cookie.categories.includes('analytics')) {
        /* eslint-disable-next-line no-undef */
        gtag('consent', 'update', {
          analytics_storage: 'granted',
          functionality_storage: 'granted',
        });
        gtag('event', 'page_view');
      }
    },

    gui_options: {
      consent_modal: {
        layout: 'cloud',
      },
    },

    languages: {
      en: {
        consent_modal: {
          title: 'Administrate DX cookies',
          description:
            'Administrate DX uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent.',
          primary_btn: {
            text: 'Accept',
            role: 'accept_all', // 'accept_selected' or 'accept_all'
          },
          secondary_btn: {
            text: 'Reject',
            role: 'accept_necessary', // 'settings' or 'accept_necessary'
          },
        },
        settings_modal: {
          title: 'Cookie preferences',
          save_settings_btn: 'Save settings',
          accept_all_btn: 'Accept all',
          reject_all_btn: 'Reject all',
          close_btn_label: 'Close',
          cookie_table_headers: [
            { col1: 'Name' },
            { col2: 'Domain' },
            { col3: 'Expiration' },
            { col4: 'Description' },
          ],
          blocks: [
            {
              title: 'Cookie usage',
              description:
                'Administrate DX uses cookies to ensure the basic functionalities of the website and to enhance your online experience.',
            },
            {
              title: 'Strictly necessary cookies',
              description:
                'These cookies are essential for the proper functioning of Administrate DX.',
              toggle: {
                value: 'necessary',
                enabled: true,
                readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
              },
            },
            {
              title: 'Performance and Analytics cookies',
              description:
                'These cookies allow Administrate DX to remember the choices you have made in the past',
              toggle: {
                value: 'analytics', // your cookie category
                enabled: false,
                readonly: false,
              },
              cookie_table: [
                // list of all expected cookies
                {
                  col1: '^_ga', // match all cookies starting with "_ga"
                  col2: 'google.com',
                  col3: '2 years',
                  col4: 'description ...',
                  is_regex: true,
                },
                {
                  col1: '_gid',
                  col2: 'google.com',
                  col3: '1 day',
                  col4: 'description ...',
                },
              ],
            },
          ],
        },
      },
    },
  });
}
