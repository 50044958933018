class Poller {
  constructor(pollTimeout) {
    this.pollTimeout = pollTimeout;
  }

  startPolling() {
    if (!this.interval) {
      this.sendPoll();
      this.interval = setInterval(this.sendPoll.bind(this), this.pollTimeout);
    }
  }

  stopPolling() {
    clearInterval(this.interval);
    this.interval = false;
  }

  sendPoll() {

  }
}

export default Poller;
