import React, { Component } from 'react';
import { Row, Col, Button, Glyphicon } from 'react-bootstrap';
import { withRouter } from 'react-router';

import Playground, {
  VIEW_STATE_SPLIT,
  VIEW_STATE_FULL,
  VIEW_STATE_HIDE,
} from './Playground';

class Explorer extends Component {
  constructor(props) {
    super(props);
    const hasLinkQuery = !!props.location.query.query;
    this.state = {
      defaultQuery: props.location.query.query,
      viewState: hasLinkQuery ? VIEW_STATE_SPLIT : VIEW_STATE_HIDE,
      documentationSize: hasLinkQuery ? 6 : 12,
      // CodeMirror seems to configure its css based on the initial column size it is created in
      // we therefore need to first render the editor with a column size of 6, then immediately
      // change the column size to to the correct value
      playgroundSize: 6,
    };
  }

  componentDidMount() {
    // Now that CodeMirror is correctly configured, resize the view to the correct size
    const hasLinkQuery = !!this.props.location.query.query;
    this.resizeView(hasLinkQuery ? VIEW_STATE_SPLIT : VIEW_STATE_HIDE);
  }

  resizeView(size) {
    switch (size) {
      case VIEW_STATE_FULL:
        this.setState({
          documentationSize: 0,
          playgroundSize: 12,
          viewState: size,
        });
        break;
      case VIEW_STATE_SPLIT:
        this.setState({
          documentationSize: 6,
          playgroundSize: 6,
          viewState: size,
        });
        break;
      case VIEW_STATE_HIDE:
        this.setState({
          documentationSize: 12,
          playgroundSize: 0,
          viewState: size,
        });
        break;
      default:
        break;
    }
  }

  computePlaygroundTabClass() {
    const classes = ['hidden-xs', 'hidden-sm'];
    if (this.state.viewState !== 'hide') {
      classes.push('hidden-md');
      classes.push('hidden-lg');
    }
    return classes.join(' ');
  }

  render() {
    if (!this.props.route.showEditor) {
      return (
        <Row id="explorer">
          <Col md={12} sm={12}>
            {this.props.children}
          </Col>
          {
            // We need to render the playground in case the user swaps over to an api that
            // can use the editor- this allows CodeMirror to configure correctly
            <Col md={this.state.playgroundSize} xsHidden smHidden>
              <Playground
                resizeView={(size) => this.resizeView(size)}
                viewState={this.state.viewState}
                defaultQuery={this.state.defaultQuery}
                showEditor={this.props.route.showEditor}
              />
            </Col>
          }
        </Row>
      );
    }

    return (
      <Row id="explorer">
        <Col md={this.state.documentationSize} sm={12}>
          {this.props.children}
        </Col>
        <Col md={this.state.playgroundSize} xsHidden smHidden>
          <Playground
            resizeView={(size) => this.resizeView(size)}
            viewState={this.state.viewState}
            defaultQuery={this.state.defaultQuery}
            showEditor={this.props.route.showEditor}
          />
        </Col>
        <Button
          id="explorer-playground-tab"
          onClick={() => this.resizeView('split')}
          className={this.computePlaygroundTabClass()}
        >
          <Glyphicon glyph="console" /> Editor
        </Button>
      </Row>
    );
  }
}

Explorer.propTypes = {
  children: React.PropTypes.oneOfType([
    React.PropTypes.arrayOf(React.PropTypes.node),
    React.PropTypes.node,
  ]).isRequired,
  location: React.PropTypes.object.isRequired,
  route: React.PropTypes.shape({
    showEditor: React.PropTypes.bool.isRequired,
  }).isRequired,
};

export default withRouter(Explorer);
