import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { buildClientSchema, GraphQLEnumType } from 'graphql';
import { Link } from 'react-router';
import isomorphicSchemaFetch from '../../utils/schemaFetch';
import { getDeprecationMetadata } from '../SchemaDocument/DeprecatedFields';

export default class DeprecationTracker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trackedDeprecatedFields: [],
    };
  }

  componentDidMount() {
    const { apiName } = this.props;

    isomorphicSchemaFetch(apiName).then((schemaData) => {
      const schema = buildClientSchema(schemaData);
      const deprecatedFields = getDeprecatedFieldsFromSchema(schema);

      const formattedData = unpackDeprecatedfieldMetadata(deprecatedFields);
      const scheduledFields = formattedData.filter(
        (field) => field.removalDate !== 'TBD',
      );

      scheduledFields.sort((a, b) => a.removalDate.localeCompare(b.removalDate));

      this.setState({ trackedDeprecatedFields: scheduledFields });
    });
  }

  render() {
    const { apiName, apiDisplayName } = this.props;
    const { trackedDeprecatedFields } = this.state;

    return (
      <div>
        <Row style={{ 'margin-bottom': '10px' }}>
          <Col lg={12}>
            <h1>
              Upcoming
              {' '}
              {apiDisplayName}
              {' '}
              API Changes
            </h1>
            <p>All removal dates are shown in ISO 8601 format (e.g. YYYY-MM-DD).</p>
          </Col>
        </Row>
        {trackedDeprecatedFields.map((field) => (
          <div>
            <Row key={field.coordinates}>
              <Col lg={8}>
                <div style={{ 'overflow-wrap': 'break-word' }}>
                  <Link to={`/docs/${apiName}/api#${field.parentType}`}>
                    {field.parentType}
                    .
                    {field.name}
                  </Link>
                </div>
                <div>
                  {field.deprecationReason}
                </div>
              </Col>
              <Col lg={4}>
                <div>
                  <strong>Removal Date</strong>
                </div>
                <div>
                  {field.removalDate}
                </div>
              </Col>
            </Row>
            <hr />
          </div>
        ))}
      </div>
    );
  }
}

DeprecationTracker.propTypes = {
  apiName: React.PropTypes.string.isRequired,
  apiDisplayName: React.PropTypes.string.isRequired,
};

function getDeprecatedFieldsFromSchema(schema) {
  const sectionNames = getSectionNames(schema);
  const isWantedSection = (sectionName) => sectionName.match(/\b__/) === null;
  const sections = sectionNames
    .filter((sectionName) => isWantedSection(sectionName))
    .map((sectionName) => schema._typeMap[sectionName]);

  const deprecatedFields = sections.flatMap((section) => {
    const isEnum = section instanceof GraphQLEnumType;
    const fields = isEnum ? section._enumConfig.values : section._fields;

    if (fields) {
      return Object.entries(fields)
        .map(([fieldName, field]) => isEnum
          ? {
            ...field,
            name: fieldName,
            parentType: section.name,
          }
          : { ...field, parentType: section.name })
        .filter((field) => getFieldStatus(field) === 'deprecated');
    }

    return [];
  });

  return deprecatedFields;
}

function getFieldStatus(field) {
  if (
    (field.isDeprecated !== undefined && field.isDeprecated)
    || !!field.deprecationReason
  ) {
    return 'deprecated';
  }
  if (
    !!field.description
    && field.description.includes('|||\n|-|-:|\n| Preview | true |')
  ) {
    return 'preview';
  }
  return 'active';
}

function getSectionNames(schema) {
  const queryAndMutation = ['Query', 'Mutation'];

  const sectionNames = Object.keys(schema._typeMap);
  const nonWantedNames = ['Query', 'Mutation'];

  nonWantedNames.forEach((name) => {
    const pos = sectionNames.indexOf(name);
    sectionNames.splice(pos, 1);
  });

  return queryAndMutation.concat(sectionNames);
}

function unpackDeprecatedfieldMetadata(deprecatedFields) {
  return deprecatedFields.map((field) => {
    const { reason, removalDate } = getDeprecationMetadata(
      field.deprecationReason,
    );
    return {
      parentType: field.parentType,
      name: field.name,
      coordinates: `${field.parentType}.${field.name}`,
      deprecationReason: reason,
      removalDate,
    };
  });
}
