import React from 'react';
import { GraphQLList, GraphQLNonNull } from 'graphql';

export default class ParameterCell extends React.Component {
  _nameOrDescriptionContent() {
    const { parameter } = this.props;
    const spans = [];

    if (parameter.name) {
      spans.push(this._name(parameter));
    }

    if (
      !this.props.isHeader &&
      parameter.description !== '' &&
      parameter.description !== null &&
      parameter.description !== parameter.name
    ) {
      spans.push(<div key="description" className="description"> {parameter.description} </div>);
    }

    if (spans.length > 0) {
      return spans;
    }

    return <div className="info"> no description available </div>;
  }

  _name(parameter) {
    return (
      <div key="name" className="parameter">
        {parameter.name}
      </div>
    );
  }

  _typeContent() {
    const { type } = this.props.parameter;
    if (!type) {
      return <span />;
    }
    const typeName = this._getTypeName(type);
    const typeNameWithoutBracketsOrExclamation = typeName.replace(/[[\]!]/g, '');
    if (typeNameWithoutBracketsOrExclamation.length > 0) {
      const anchorLink = `#${typeNameWithoutBracketsOrExclamation}`;
      return <a className="type" href={anchorLink}>{typeName}</a>;
    }
    return <span className="info">Unknown</span>;
  }

  _getTypeName(type) {
    if ('name' in type) {
      return type.name;
    } else if (type instanceof GraphQLList) {
      return `[${this._getTypeName(type.ofType)}]`;
    } else if (type instanceof GraphQLNonNull) {
      return `${this._getTypeName(type.ofType)}!`;
    }
    return '';
  }

  render() {
    return (
      <td rowSpan={this.props.isHeader ? this.props.rowSpan : null}>
        {this._nameOrDescriptionContent()}
        {this._typeContent()}
      </td>
    );
  }
}

ParameterCell.propTypes = {
  // eslint-disable-next-line react/no-typos
  parameter: React.PropTypes.object,
  // eslint-disable-next-line react/no-typos
  isHeader: React.PropTypes.bool,
  // eslint-disable-next-line react/no-typos
  rowSpan: React.PropTypes.number,
};

ParameterCell.defaultProps = {
  parameter: {
    name: '',
    type: '',
  },
  isHeader: false,
  rowSpan: 0,
};
