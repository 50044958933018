import React from 'react';
import SchemaDocument from './SchemaDocument/SchemaDocument';

export default function CoreSchemaDocument() {
  return (
    <SchemaDocument
      apiName="core"
      displayName="Core"
      description="This is the complete reference documentation for the types and
    relations in the Administrate Core GraphQL API."
    />
  );
}
