import React from 'react';
import ParameterCell from './ParameterCell';

export default function ObjectTypeFieldRow({ field }) {
  const description = field.description || '';
  const args = field.args || [];

  const graphQLArguments = args.map(parameter => (
    <tr key={parameter.name}>
      <ParameterCell parameter={parameter} />
    </tr>
  ));

  return (
    <tbody>
      <tr>
        <ParameterCell
          parameter={field}
          isHeader
          rowSpan={graphQLArguments.length + 1}
        />
        <ParameterCell parameter={{ description }} />
      </tr>
      {graphQLArguments}
    </tbody>
  );
}

ObjectTypeFieldRow.propTypes = {
  field: React.PropTypes.object.isRequired,
};
