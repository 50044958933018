import React from 'react';
import { FormControl } from 'react-bootstrap';


class FormControlPassword extends React.Component {
  static propTypes = {
    name: React.PropTypes.string.isRequired,
    value: React.PropTypes.string.isRequired,
    onChange: React.PropTypes.func.isRequired,
    autoComplete: React.PropTypes.string,
    testId: React.PropTypes.string,
  };

  static defaultProps = {
    autoComplete: 'on',
    testId: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      shown: false,
    };
    this.toggleShown = this.toggleShown.bind(this);
  }

  toggleShown() {
    this.setState({
      shown: !this.state.shown,
    });
  }

  render() {
    return (
      <div className="form-control-password">
        <FormControl
          name={this.props.name}
          type={this.state.shown ? 'text' : 'password'}
          value={this.props.value}
          onChange={this.props.onChange}
          autoComplete={this.props.autoComplete}
          data-test-id={this.props.testId}
        />
        <span className="form-control-password-toggle" onClick={this.toggleShown}>
          {this.state.shown ? 'Hide' : 'Show'}
        </span>
      </div>
    );
  }
}

export default FormControlPassword;
