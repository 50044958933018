import React from 'react';
import { Link } from 'react-router';

import isomorphicSchemaFetch from '../../utils/schemaFetch';

function endsWith(value, ending) {
  return value.substr(value.length - ending.length) === ending;
}

class SchemaTableOfContentsBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      types: [],
    };
    this.loadTypes();
  }

  loadTypes() {
    isomorphicSchemaFetch(this.props.apiName).then((schemaData) => {
      const { types } = schemaData.__schema;
      types.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });
      this.setState({
        types,
      });
    });
  }

  render() {
    const unwantedEndings = [
      'Connection',
      'Edge',
      'Field',
      'FieldGraphFilter',
      'FieldGraphOrder',
    ];
    const items = [];
    this.state.types.forEach((type) => {
      let include = true;
      if (type.name[0] === '_') {
        include = false;
      }
      unwantedEndings.forEach((ending) => {
        if (endsWith(type.name, ending)) {
          include = false;
        }
      });
      if (include) {
        items.push(
          <tr>
            <td>
              <Link to={`/docs/${this.props.apiName}/api#${type.name}`}>
                {type.name}
              </Link>
            </td>
            <td>{type.description}</td>
          </tr>,
        );
      }
    });
    return <tbody>{items}</tbody>;
  }
}

SchemaTableOfContentsBody.propTypes = {
  apiName: React.PropTypes.string.isRequired,
};

export default function SchemaTableOfContents({ apiName }) {
  return (
    <table className="table">
      <thead>
        <tr>
          <th className="col-md-4">Entity</th>
          <th className="col-md-8">Description</th>
        </tr>
      </thead>
      <SchemaTableOfContentsBody apiName={apiName} />
    </table>
  );
}

SchemaTableOfContents.propTypes = {
  apiName: React.PropTypes.string.isRequired,
};
