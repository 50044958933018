const PROTOCOL = '^(?:(?:https?):\\/\\/)';
const OPTIONAL_INLINE_AUTHENTICATION = '(?:\\S+(?::\\S*)?@)?';

const FIRST_IP = '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])';
const SECOND_AND_THIRD_IP = '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}';
const FOURTH_IP = '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))';

const FIRST_DOMAIN = '(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)';
const REPEATED_POST_DOMAINS = '(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*';
const FINAL_DOMAIN = '(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))';

const OPTIONAL_PORT = '(?::\\d{2,5})?';
const PATH_WITH_QUERY = '(?:[/?#]\\S*)?';
const PATH_WITHOUT_QUERY = '([/][^\\r\\n\\t\\f #?]*)?';

const IP_ADDRESS = `${FIRST_IP}${SECOND_AND_THIRD_IP}${FOURTH_IP}`;
const DOMAINS = `${FIRST_DOMAIN}${REPEATED_POST_DOMAINS}${FINAL_DOMAIN}\\.?)`;
const URN = `(?:${IP_ADDRESS}|${DOMAINS}${OPTIONAL_PORT}`;

const BASE_URL_PATTERN = `${PROTOCOL}${OPTIONAL_INLINE_AUTHENTICATION}${URN}`;

function isValidUrl(url) {
  const pattern = new RegExp(`${BASE_URL_PATTERN}${PATH_WITH_QUERY}$`, 'g');
  return pattern.test(url);
}

function isValidUrlWithoutQueryParams(url) {
  const pattern = new RegExp(`${BASE_URL_PATTERN}${PATH_WITHOUT_QUERY}$`, 'g');
  return pattern.test(url);
}

export { isValidUrl, isValidUrlWithoutQueryParams };
