import React from 'react';

import NavigationTree from './NavigationTree';


export default class NavigatorCollapsibleItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.isOpen,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      isOpen: nextProps.isOpen,
    });
  }

  onClick() {
    if (!this.state.isOpen) {
      this.props.onOpen();
    }
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  }

  render() {
    const LIST_ITEM_HEIGHT = 28;
    const LIST_GROUP_PADDING = 80;
    let isActive = false;
    Object.keys(this.props.items).forEach((key) => {
      if (this.context.router.isActive(this.props.items[key])) {
        isActive = true;
      }
    });
    let style = {
      'max-height': '40px',
    };
    if (this.state.isOpen || isActive) {
      style = {
        'max-height': `${(Object.keys(this.props.items).length * LIST_ITEM_HEIGHT) + LIST_GROUP_PADDING}px`,
      };
    }
    const className = isActive ? 'active' : '';
    const iconName = this.state.isOpen || isActive ? 'glyphicon-menu-up' : 'glyphicon-menu-down';
    const iconColor = this.state.isOpen || isActive ? 'blue' : '';
    return (
      <div className={`collapsible-list-group-item list-group-item ${className}`} onClick={() => this.onClick()} style={style}>
        {this.props.title}
        <span className={`glyphicon ${iconName} pull-right ${iconColor}`} style={{ 'margin-top': '2px' }} />
        <NavigationTree items={this.props.items} isOpen />
      </div>
    );
  }
}

NavigatorCollapsibleItem.contextTypes = {
  router: React.PropTypes.object,
};

NavigatorCollapsibleItem.propTypes = {
  title: React.PropTypes.string.isRequired,
  items: React.PropTypes.any.isRequired,
  onOpen: React.PropTypes.func.isRequired,
  isOpen: React.PropTypes.bool,
};

NavigatorCollapsibleItem.defaultProps = {
  isOpen: false,
};
