export default class PubSub {
  constructor() {
    this.channels = {};
  }

  subscribe(channel, callback) {
    if (!this.hasChannel(channel)) {
      this.createChannel(channel);
    }
    if (!this.hasChannelCallback(channel, callback)) {
      this.addChannelCallback(channel, callback);
    }
  }

  unsubscribe(channel, callback) {
    if (!this.hasChannel(channel)) {
      return;
    }
    this.removeChannelCallback(channel, callback);
  }

  publish(channel, message) {
    if (!this.hasChannel(channel)) {
      return;
    }
    const channelCallbacks = this.getChannelCallbacks(channel);
    this.callAllWithArgument(channelCallbacks, message);
  }

  hasChannel(channel) {
    return !!this.getChannelCallbacks(channel);
  }

  createChannel(channel) {
    this.channels[channel] = [];
  }

  getChannelCallbacks(channel) {
    return this.channels[channel];
  }

  hasChannelCallback(channel, callback) {
    return this.getChannelCallbacks(channel).indexOf(callback) !== -1;
  }

  addChannelCallback(channel, callback) {
    this.getChannelCallbacks(channel).push(callback);
  }

  removeChannelCallback(channel, callback) {
    const callbacks = this.getChannelCallbacks(channel);
    const callbackIndex = callbacks.indexOf(callback);
    if (callbackIndex !== -1) {
      callbacks.splice(callbackIndex, 1);
    }
  }

  callAllWithArgument(list, argument) {
    for (let i = 0; i < list.length; i += 1) {
      list[i](argument);
    }
  }
}
