import React from 'react';

import { Alert } from 'react-bootstrap';


class KitAlert extends React.Component {
  ICON_ALERT_TYPE_MAP = new Map([
    ['danger', 'minus'],
    ['info', 'info'],
    ['warning', 'exclamation'],
    ['success', 'ok'],
  ]);

  constructor(props) {
    super(props);
    this.state = {
      alertType: props.alertType,
    };
  }

  render() {
    return (
      <Alert bsStyle={this.state.alertType}>
        <div className="alert-icon">
          <span className={`glyphicon glyphicon-${this.getGlyphIcon()}-sign`} />
        </div>
        <div className="alert-text">
          {this.props.children}
        </div>
      </Alert>
    );
  }

  getGlyphIcon() {
    return this.ICON_ALERT_TYPE_MAP.get(this.state.alertType);
  }
}

export default KitAlert;

KitAlert.propTypes = {
  alertType: React.PropTypes.string,
  children: React.PropTypes.node.isRequired,
};

KitAlert.defaultProps = {
  alertType: 'info',
};

