import { GraphQLEnumType } from 'graphql';
import { groupBy } from 'lodash';
import React from 'react';
import DeprecatedFields from './DeprecatedFields';
import PreviewFields from './PreviewFields';
import EnumTypeValueRow from './EnumTypeValueRow';
import ObjectTypeFieldRow from './ObjectTypeFieldRow';
import TypeDocumentationTable from './TypeDocumentationTable';

function getFieldStatus(field) {
  if (
    (field.isDeprecated !== undefined && field.isDeprecated) ||
    !!field.deprecationReason
  ) {
    return 'deprecated';
  }
  if (
    !!field.description &&
    field.description.includes('|||\n|-|-:|\n| Preview | true |')
  ) {
    return 'preview';
  }
  return 'active';
}

export default function SchemaSection(props) {
  const { section } = props;

  const isWantedSection = section.name.match(/\b__/) === null;

  if (!isWantedSection) {
    return null;
  }

  const idString = section.name;
  const isEnum = section instanceof GraphQLEnumType;
  const fields = isEnum ? section._enumConfig.values : section._fields;

  let deprecatedFields = [];
  let activeFields = [];
  let previewFields = [];

  if (fields) {
    ({
      deprecated: deprecatedFields,
      active: activeFields,
      preview: previewFields,
    } = groupBy(
      Object.entries(fields).map(([fieldName, field]) =>
        isEnum ? { ...field, name: fieldName } : field,
      ),
      (field) => getFieldStatus(field),
    ));
  }

  const Row = isEnum ? EnumTypeValueRow : ObjectTypeFieldRow;
  const tableColumns = [
    { width: 4, label: isEnum ? 'Values' : 'Fields' },
    { width: 8, label: 'Description' },
  ];

  return (
    <div id={idString} className="sectionContainer">
      <div>
        <a href="#api-top" className="pull-right">
          Top
        </a>
        <h2>{section.name}</h2>
      </div>
      <p>{section.description}</p>
      <TypeDocumentationTable
        fields={activeFields}
        columns={tableColumns}
        Row={Row}
      />
      {previewFields && !!previewFields.length && (
        <PreviewFields fields={previewFields} isEnum={isEnum} />
      )}
      {deprecatedFields && !!deprecatedFields.length && (
        <DeprecatedFields fields={deprecatedFields} isEnum={isEnum} />
      )}
    </div>
  );
}

SchemaSection.propTypes = {
  section: React.PropTypes.any.isRequired,
};
