import React, { Component } from 'react';
import { HelpBlock } from 'react-bootstrap';

class ValidationHelpBlock extends Component {
  render() {
    if (this.props.errors && this.props.errors.length) {
      return (
        <HelpBlock>
          {this.props.errors.map((err) => (
            <p key={err}>{err}</p>
          ))}
        </HelpBlock>
      );
    }
    return null;
  }
}

ValidationHelpBlock.propTypes = {
  errors: React.PropTypes.arrayOf(React.PropTypes.string),
};

ValidationHelpBlock.defaultProps = {
  errors: [],
};

export default ValidationHelpBlock;
