import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import revalidator from 'revalidator';
import { FormGroup, ControlLabel, FormControl, Button, Col, Row, ProgressBar, HelpBlock } from 'react-bootstrap';
import ValidationHelpBlock from '../forms/ValidationHelpBlock';
import FormControlPassword from '../forms/FormControlPassword';
import KitAlert from '../../components/KitAlert';
import { authServer, messageStack } from '../../utils/services';
import { countPasswordCharacterGroups } from '../../utils/validation';
import Instances from './Instances';

export default class Profile extends Component {
  VALIDATION_SCHEMA = {
    properties: {
      firstName: {
        name: 'First name',
        type: 'string',
        allowEmpty: false,
        maxLength: 35,
        required: true,
      },
      lastName: {
        name: 'Last name',
        type: 'string',
        allowEmpty: false,
        maxLength: 35,
        required: true,
      },
      email: {
        name: 'Email',
        type: 'string',
        allowEmpty: false,
        maxLength: 256,
        format: 'email',
        required: true,
      },
      companyName: {
        name: 'Company name',
        type: ['string', 'null'],
        allowEmpty: true,
        maxLength: 50,
      },
      mobileNumber: {
        name: 'Mobile number',
        type: ['string', 'null'],
        allowEmpty: true,
        pattern: '^[+0-9() -]*$',
        maxLength: 20,
      },
      password: {
        name: 'Password',
        type: 'string',
        allowEmpty: false,
        minLength: 8,
        maxLength: 255,
        conform: p => countPasswordCharacterGroups(p) >= 3,
        messages: {
          conform: 'should contain 3 of the 4 following character sets: lowercase, uppercase, numbers, and symbols',
        },
      },
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      serverError: null,
      errors: {},
      profile: {},
      awaitingData: true,
      dirty: null,
      successMessage: messageStack.pop(),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resendEmailVerification = this.resendEmailVerification.bind(this);
  }

  componentDidMount() {
    this.getProfile();
  }

  getProfile() {
    authServer.getProfile().then(
      this.onGetProfileSuccess.bind(this),
      this.onGetProfileFailure.bind(this),
    );
  }

  onGetProfileSuccess(profile) {
    this.setState({
      profile,
      awaitingData: false,
      dirty: null,
    });
  }

  onGetProfileFailure() {
    browserHistory.push('/login');
  }

  handleChange(event) {
    const { target } = event;
    const { errors, profile } = this.state;
    profile[target.name] = target.value;
    errors[target.name] = null;
    this.setState({
      profile,
      errors,
      dirty: true,
    });
  }

  handleSubmit(event) {
    if (event) {
      event.preventDefault();
    }

    this.setState({
      successMessage: null,
      serverError: null,
    });

    const validation = revalidator.validate(this.state.profile, this.VALIDATION_SCHEMA);
    if (!validation.valid) {
      const errors = {};
      validation.errors.forEach((error) => {
        const errorString = `${this.VALIDATION_SCHEMA.properties[error.property].name} ${error.message}`;
        if (!(error.property in errors)) {
          errors[error.property] = [];
        }
        errors[error.property].push(errorString);
      });
      this.setState({ errors });
      return;
    }

    authServer.updateProfile(this.state.profile).then(
      this.handleSuccess.bind(this),
      this.handleError.bind(this),
    );
  }

  handleSuccess(profile) {
    this.setState({
      profile,
      dirty: false,
    });
  }

  handleError(error) {
    if (error.statusCode === 401) {
      browserHistory.push('/login');
    }

    // reset the profile
    this.getProfile();

    this.setState({
      serverError: error.message,
    });
  }

  getValidationState(control) {
    const errors = this.state.errors[control];
    return errors && errors.length ? 'error' : null;
  }

  render() {
    return (
      <div>
        <div className="profile" >
          <h1>Profile</h1>
          <p className="description">
            Edit the details associated with your account.
          </p>
          {this.checkIfLoading()}
        </div>
        <Instances />
      </div>
    );
  }

  checkIfLoading() {
    if (this.state.awaitingData) {
      return this.loadingBar();
    }
    return this.form();
  }

  loadingBar() {
    return (
      <div>
        <p className="description">Fetching your profile ...</p>
        <ProgressBar active now={100} />
      </div>
    );
  }

  form() {
    const { profile, errors } = this.state;
    return (
      <form onSubmit={this.handleSubmit} >
        {this.state.successMessage ? <KitAlert className="successMessage" alertType="success">{this.state.successMessage}</KitAlert> : null }
        {this.state.serverError ? <KitAlert className="serverError" alertType="danger">{this.state.serverError}</KitAlert> : null}
        <Row>
          <Col className="firstNameField" componentClass={FormGroup} sm={6} validationState={this.getValidationState('firstName')}>
            <ControlLabel htmlFor="firstName">First name</ControlLabel>
            <FormControl id="firstName" name="firstName" type="text" value={profile.firstName} onChange={this.handleChange} />
            <ValidationHelpBlock errors={errors.firstName} />
          </Col>
          <Col className="lastNameField" componentClass={FormGroup} sm={6} validationState={this.getValidationState('lastName')}>
            <ControlLabel htmlFor="lastName">Last name</ControlLabel>
            <FormControl id="lastName" name="lastName" type="text" value={profile.lastName} onChange={this.handleChange} />
            <ValidationHelpBlock errors={errors.lastName} />
          </Col>
        </Row>
        <FormGroup controlId="email" validationState={this.getValidationState('email')}>
          {this.pendingEmailVerification(profile.pendingEmail)}
          <ControlLabel>Email</ControlLabel>
          <FormControl name="email" type="text" value={profile.email} onChange={this.handleChange} />
          <ValidationHelpBlock errors={errors.email} />
        </FormGroup>
        <FormGroup controlId="companyName" validationState={this.getValidationState('companyName')}>
          <ControlLabel>
            Company name
            {' '}
            <span className="optional">(optional)</span>
          </ControlLabel>
          <FormControl name="companyName" type="text" value={profile.companyName} onChange={this.handleChange} />
          <ValidationHelpBlock errors={errors.companyName} />
        </FormGroup>
        <FormGroup controlId="mobileNumber" validationState={this.getValidationState('mobileNumber')}>
          <ControlLabel>
            Mobile number
            {' '}
            <span className="optional">(optional)</span>
          </ControlLabel>
          <FormControl name="mobileNumber" type="text" value={profile.mobileNumber} onChange={this.handleChange} />
          <ValidationHelpBlock errors={errors.mobileNumber} />
        </FormGroup>
        <FormGroup controlId="password" validationState={this.getValidationState('password')}>
          <ControlLabel>Password</ControlLabel>
          <FormControlPassword name="password" value={profile.password} autoComplete="off" onChange={this.handleChange} />
          <HelpBlock>
            Must be at least 8 characters and contain 3 of the 4 following character sets:
            lowercase, uppercase, numbers, and symbols.
          </HelpBlock>
        </FormGroup>
        {this.submitButton()}
      </form>
    );
  }

  pendingEmailVerification(pendingEmail) {
    if (!pendingEmail) {
      return null;
    }

    return (
      <p className="pendingEmail pull-right">
        <span className="glyphicon glyphicon-exclamation-sign glyph-careful" />
        <span className="notification-text">
          Update email to {pendingEmail} pending verification.
          <a className="notification-link" onClick={this.resendEmailVerification}>Resend verification</a>
        </span>
      </p>
    );
  }

  resendEmailVerification() {
    this.setState({ successMessage: null, serverError: null });

    authServer.resendChangeEmailVerification({ email: this.state.profile.email }).then(
      this.handleResendEmailVerificationSuccess.bind(this),
      this.handleResendEmailVerificationError.bind(this),
    );
  }

  handleResendEmailVerificationSuccess() {
    this.setState({ successMessage: 'The email verification was re-sent successfully' });
  }

  handleResendEmailVerificationError() {
    this.setState({ serverError: 'An error occured while sending the email. Please try again.' });
  }

  submitButton() {
    if (this.state.dirty === true) {
      return <Button bsStyle="primary" type="submit">Save changes</Button>;
    } else if (this.state.dirty === false) {
      return <Button bsStyle="default" type="submit" disabled>Changes saved</Button>;
    }

    return <Button bsStyle="default" type="submit" disabled>No changes made</Button>;
  }
}
