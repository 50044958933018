import lunr from 'lunr';

export default class SearchEngine {
  constructor() {
    this.store = {};
    this.index = lunr(function lunrSetup() {
      this.field('title', { boost: 10 });
      this.field('body');
      this.field('fields');
      this.ref('href');
    });
  }

  add(item) {
    this.index.add(item);
    this.store[item.href] = item;
  }

  search(query) {
    const entries = this.index.search(query);

    return entries.map((result) => {
      const { ref } = result;
      const item = this.store[ref];

      return {
        href: ref,
        title: item.title,
        type: item.type,
        category: item.category,
      };
    });
  }
}
