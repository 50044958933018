import React, { Component } from 'react';
import { FormGroup, ControlLabel, FormControl, Button } from 'react-bootstrap';
import { Link } from 'react-router';
import { authServer, messageStack } from '../../utils/services';
import { isValidEmail } from '../../utils/validation';
import DeveloperPage from '../DeveloperPage';
import ValidationHelpBlock from '../forms/ValidationHelpBlock';
import KitAlert from '../../components/KitAlert';


class RequestPasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      done: false,
      serverError: null,
      errors: [],
      errorMessage: messageStack.pop(),
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleEmailChange(event) {
    this.setState({
      email: event.target.value,
      serverError: null,
      errors: [],
    });
  }

  handleSubmit(event) {
    if (event) {
      event.preventDefault();
    }

    if (!isValidEmail(this.state.email)) {
      this.setState({ errors: ['Valid email is required'] });
      return;
    }

    authServer.requestPasswordReset(this.state.email)
      .then(this.handleSuccess.bind(this))
      .catch(this.handleError.bind(this));
  }

  handleSuccess() {
    this.setState({
      done: true,
    });
  }

  handleError(error) {
    this.setState({
      serverError: error.message,
    });
  }

  render() {
    return (
      <DeveloperPage dpStyle="dark" title="Forgot password">
        <p className="description">
          Don&apos;t worry, just enter your email address and
          we will send you a link to reset your password.
        </p>
        {this.getFormOrDoneMessage(this.state.done)}
        <p className="login">Remembered your password? <Link to="/login">Log in</Link></p>
      </DeveloperPage>
    );
  }

  getFormOrDoneMessage(done) {
    if (done) {
      return this.getDoneMessage();
    }
    return this.getForm();
  }

  getForm() {
    let alert = null;
    if (this.state.serverError) {
      alert = <KitAlert alertType="danger">{this.state.serverError}</KitAlert>;
    }
    return (
      <form onSubmit={this.handleSubmit} >
        {alert}
        {this.state.errorMessage ? <KitAlert alertType="danger">{this.state.errorMessage}</KitAlert> : null}
        <FormGroup controlId="email" validationState={this.getValidationState()}>
          <ControlLabel>Email</ControlLabel>
          <FormControl type="email" name="email" value={this.state.email} onChange={this.handleEmailChange} />
          <ValidationHelpBlock errors={this.state.errors} />
        </FormGroup>
        <Button bsStyle="primary" type="submit">Reset my password</Button>
      </form>
    );
  }

  getValidationState() {
    const { errors } = this.state;
    return errors && errors.length ? 'error' : null;
  }

  getDoneMessage() {
    return (
      <div className="doneMessage">
        <p>
          <span className="glyphicon glyphicon-ok-sign glyph-background" >
            <span className="inner" />
          </span>
          Check your email for the password reset link.
        </p>
        <Button bsStyle="outline-secondary" disabled>Email sent</Button>
      </div>
    );
  }
}

export default RequestPasswordReset;
