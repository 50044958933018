import React from 'react';
import marked from 'marked';

import { PrismLight as SyntaxHighlighter} from 'react-syntax-highlighter';
import javascript from 'react-syntax-highlighter/dist/cjs/languages/prism/javascript';
import graphql from 'react-syntax-highlighter/dist/cjs/languages/prism/graphql';
import shell from 'react-syntax-highlighter/dist/cjs/languages/prism/shell-session';
import json from 'react-syntax-highlighter/dist/cjs/languages/prism/json';
import python from 'react-syntax-highlighter/dist/cjs/languages/prism/python';
import php from 'react-syntax-highlighter/dist/cjs/languages/prism/php';
import { coldarkCold as style } from 'react-syntax-highlighter/dist/cjs/styles/prism';

// Recommended approach for a lighter footprint is to register just the languages we know are needed
// https://github.com/react-syntax-highlighter/react-syntax-highlighter#light-build
SyntaxHighlighter.registerLanguage('javascript', javascript);
SyntaxHighlighter.registerLanguage('graphql', graphql);
SyntaxHighlighter.registerLanguage('shell', shell);
SyntaxHighlighter.registerLanguage('json', json);
SyntaxHighlighter.registerLanguage('python', python);
SyntaxHighlighter.registerLanguage('php', php);


class ReactMarkdownRenderer extends marked.Renderer {

  constructor() {
    super();
    this._nextKey = 0;
  }

  nextKey() {
    return ++this._nextKey;
  }

  blockquote(quote) {
    return <blockquote key={this.nextKey()}>{quote}</blockquote>;
  }

  code(code, lang) {
    if (lang === 'graphql-tryme') {
      return (
        <div className="try-me-example" key={this.nextKey()}>
          <button
            data-publish-tryme={code}
            className="btn btn-primary pull-right hidden-xs hidden-sm"
          >
            Try Me
            <span className="glyphicon glyphicon-chevron-right" />
          </button>
          <h3>
            <span className="hidden-xs hidden-sm">Live </span>
            Example
          </h3>
          <SyntaxHighlighter language="graphql" style={style} customStyle={{ margin: 0 }}>
            {code}
          </SyntaxHighlighter>
        </div>
      );
    } else {
      return (<SyntaxHighlighter key={this.nextKey()} language={lang} style={style}>
        {code}
      </SyntaxHighlighter>)
    }
  }

  heading(text, level) {
    const Tag = `h${level}`;
    return <Tag key={this.nextKey()}>{text}</Tag>;
  }

  html(html) {
    return <div key={this.nextKey()} dangerouslySetInnerHTML={{ __html: html.join('') }} />;
  }

  hr() {
    return <hr key={this.nextKey()} />;
  }

  list(body, ordered) {
    const List = ordered ? 'ol' : 'ul';
    return <List key={this.nextKey()}>{body}</List>;
  }

  listitem(text) {
    return <li key={this.nextKey()} >{text}</li>;
  }

  paragraph(text) {
    return <p key={this.nextKey()}>{text}</p>;
  }

  table(header, body) {
    return (<table key={this.nextKey()}>
      <thead>{header}</thead>
      <tbody>{body}</tbody>
    </table>);
  }

  tablerow(content) {
    return <tr key={this.nextKey()} >{content}</tr>;
  }

  tablecell(content, flags) {
    const Cell = flags.header ? 'th' : 'td';
    return <Cell key={this.nextKey()} style={{ textAlign: flags.align }}>{content}</Cell>;
  }

  strong(text) {
    return <strong key={this.nextKey()}>{text}</strong>;
  }

  em(text) {
    return <em key={this.nextKey()}>{text}</em>;
  }

  codespan(text) {
    return <code key={this.nextKey()}>{text}</code>;
  }

  br() {
    return <br key={this.nextKey()} />;
  }

  del(text) {
    return <del key={this.nextKey()}>{text}</del>;
  }

  link(href, title, text) {
    return <a key={this.nextKey()} href={href} title={title}>{text}</a>;
  }

  image(href, title, text) {
    return <img key={this.nextKey()} src={href} alt={text} title={title} />;
  }

  text = function (text) {
    return text;
  };

}


class ReactInlineLexer extends marked.InlineLexer {

  output(src) {
    let out = [],
      link,
      text,
      href,
      cap;

    while (src) {
            // escape
      if (cap = this.rules.escape.exec(src)) {
        src = src.substring(cap[0].length);
        out.push(cap[1]);
        continue;
      }

            // autolink
      if (cap = this.rules.autolink.exec(src)) {
        src = src.substring(cap[0].length);
        if (cap[2] === '@') {
          text = cap[1][6] === ':'
                        ? cap[1].substring(7)
                        : cap[1];
          href = `mailto:${text}`;
        } else {
          text = cap[1];
          href = text;
        }
        out.push(React.DOM.a({ href }, text));
        continue;
      }

            // url (gfm)
      if (cap = this.rules.url.exec(src)) {
        src = src.substring(cap[0].length);
        text = cap[1];
        href = text;
        out.push(React.DOM.a({ href }, text));
        continue;
      }

            // tag
      if (cap = this.rules.tag.exec(src)) {
        src = src.substring(cap[0].length);
        if (cap[0].startsWith("<img ")) {
          out.push(this.outputImageHtml(cap[0]));
        } else {
          out.push(cap[0]);
        }
        continue;
      }

            // link
      if (cap = this.rules.link.exec(src)) {
        src = src.substring(cap[0].length);
        out.push(this.outputLink(cap, {
          href: cap[2],
          title: cap[3],
        }));
        continue;
      }

            // reflink, nolink
      if ((cap = this.rules.reflink.exec(src))
                || (cap = this.rules.nolink.exec(src))) {
        src = src.substring(cap[0].length);
        link = (cap[2] || cap[1]).replace(/\s+/g, ' ');
        link = this.links[link.toLowerCase()];
        if (!link || !link.href) {
          out.push(...this.output(cap[0][0]));
          src = cap[0].substring(1) + src;
          continue;
        }
        out.push(this.outputLink(cap, link));
        continue;
      }

            // strong
      if (cap = this.rules.strong.exec(src)) {
        src = src.substring(cap[0].length);
        out.push(React.DOM.strong(null, this.output(cap[2] || cap[1])));
        continue;
      }

            // em
      if (cap = this.rules.em.exec(src)) {
        src = src.substring(cap[0].length);
        out.push(React.DOM.em(null, this.output(cap[2] || cap[1])));
        continue;
      }

            // code
      if (cap = this.rules.code.exec(src)) {
        src = src.substring(cap[0].length);
        out.push(React.DOM.code(null, cap[2]));
        continue;
      }

            // br
      if (cap = this.rules.br.exec(src)) {
        src = src.substring(cap[0].length);
        out.push(React.DOM.br(null, null));
        continue;
      }

            // del (gfm)
      if (cap = this.rules.del.exec(src)) {
        src = src.substring(cap[0].length);
        out.push(React.DOM.del(null, this.output(cap[1])));
        continue;
      }

            // text
      if (cap = this.rules.text.exec(src)) {
        src = src.substring(cap[0].length);
        out.push(this.smartypants(cap[0]));
        continue;
      }

      if (src) {
        throw new Error(`Infinite loop on byte: ${src.charCodeAt(0)}`);
      }
    }

    return out;
  }

  outputImageHtml(rawHtml) {
    return React.DOM.div({
      className: 'screenshot-image',
      dangerouslySetInnerHTML: { __html: rawHtml },
    })
  }

  outputLink(cap, link) {
    if (cap[0][0] !== '!') {
      const shouldOpenInNewWindow =
                link.href.charAt(0) !== '/'
                && link.href.charAt(0) !== '#';

      return React.DOM.a({
        href: link.href,
        title: link.title,
        target: shouldOpenInNewWindow ? '_blank' : null,
        rel: shouldOpenInNewWindow ? 'noopener noreferrer' : null,
      }, this.output(cap[1]));
    } else {
      return React.DOM.img({
        src: link.href,
        alt: cap[1],
        title: link.title,
        className: "screenshot-image"
      }, null);
    }
  }

}


class ReactMarkdownParser extends marked.Parser {

  constructor() {
    const renderer = new ReactMarkdownRenderer();
    super({ renderer });
  }

  parse(src) {
    this.inline = new ReactInlineLexer(src.links, this.options, this.renderer);
    this.tokens = src.reverse();
    const out = [];
    while (this.next()) {
      const element = this.tok();
      out.push(element);
    }
    return out;
  }

  render(text) {
    const src = marked.lexer(text);
    return this.parse(src);
  }

  tok() {
    switch (this.token.type) {
      case 'space': {
        return [];
      }
      case 'table': {
        let header = [],
          body = [],
          i,
          row,
          cell,
          flags,
          j;

                // header
        cell = [];
        for (i = 0; i < this.token.header.length; i++) {
          flags = { header: true, align: this.token.align[i] };
          cell.push(this.renderer.tablecell(
                        this.inline.output(this.token.header[i]),
                        { header: true, align: this.token.align[i] }),
                    );
        }
        header.push(this.renderer.tablerow(cell));

        for (i = 0; i < this.token.cells.length; i++) {
          row = this.token.cells[i];

          cell = [];
          for (j = 0; j < row.length; j++) {
            cell.push(this.renderer.tablecell(
                            this.inline.output(row[j]),
                            { header: false, align: this.token.align[j] },
                        ));
          }

          body.push(this.renderer.tablerow(cell));
        }
        return this.renderer.table(header, body);
      }
      case 'list_start': {
        let body = [],
          ordered = this.token.ordered;

        while (this.next().type !== 'list_end') {
          body.push(this.tok());
        }

        return this.renderer.list(body, ordered);
      }
      case 'list_item_start': {
        const body = [];
        while (this.next().type !== 'list_item_end') {
          body.push(this.token.type === 'text'
                        ? this.parseText()
                        : this.tok());
        }

        return this.renderer.listitem(body);
      }
      case 'loose_item_start': {
        const body = [];

        while (this.next().type !== 'list_item_end') {
          body.push(this.tok());
        }

        return this.renderer.listitem(body);
      }
      case 'blockquote_start': {
        const body = [];

        while (this.next().type !== 'blockquote_end') {
          body.push(this.tok());
        }

        return this.renderer.blockquote(body);
      }
      default: {
        return super.tok();
      }
    }
  }

}


export default class MarkdownDocument extends React.Component {

  constructor(props, context, updater) {
    super(props, context, updater);
    this.markdownParser = new ReactMarkdownParser();
  }

  render() {
    return (
      <div>{this.markdownParser.render(this.props.children)}</div>
    );
  }

}
