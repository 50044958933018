import { IS_SERVER, IS_BROWSER, IS_TEST } from './Environment';

const clientPromisedSchemas = {
  core: null,
  lms: null,
  weblink: null,
};

function clientSchemaFetch(apiName) {
  if (clientPromisedSchemas[apiName] == null) {
    clientPromisedSchemas[apiName] = fetch(`/schema/${apiName}`)
      .then((response) => response.json())
      .then((jsonBlob) => jsonBlob.data);
  }

  return clientPromisedSchemas[apiName];
}

function serverSchemaFetch(apiName) {
  // on the server we proxy to our known s3 endpoint
  return fetch(`https://administrate-api-schemas.s3.eu-west-1.amazonaws.com/${apiName}/latest.json`)
    .then((response) => response.json())
    .then((jsonBlob) => jsonBlob.data);
}

/** Call with either core | lms | weblink */
export default function isomorphicSchemaFetch(apiName) {
  if (IS_SERVER || IS_TEST) {
    return serverSchemaFetch(apiName);
  }
  if (IS_BROWSER) {
    return clientSchemaFetch(apiName);
  }

  throw new Error('Unknown environment');
}
