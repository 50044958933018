import { getTitleByPath } from './articles';

const getPageNamePieces = (requestPath, startingPieces) => {
  const title = getTitleByPath(requestPath);
  if (title) {
    return startingPieces.concat([title]);
  }

  return startingPieces;
};

const TITLE_DELIMIT = ' | ';

export function buildTitleString(requestPath, matchedRoutes = []) {
  const routeTitles = matchedRoutes
    .map((route) => route.title || null)
    .filter((title) => title != null);

  const pieces = getPageNamePieces(requestPath, routeTitles);

  return pieces.join(TITLE_DELIMIT);
}
