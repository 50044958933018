import React from 'react';
import marked from 'marked';
import { Glyphicon, Button } from 'react-bootstrap';
import ParameterCell from './ParameterCell';
import TypeDocumentationTable from './TypeDocumentationTable';

export default class PreviewFields extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      documentationTableColumns: [
        { width: 4, label: 'Fields' },
        { width: 8, label: 'Description' },
      ],
    };
  }

  expand = () => {
    this.setState(() => ({ expanded: true }));
  };

  render() {
    return (
      <div className="preview-fields-section">
        <div>
          <h4>
            <Glyphicon className="preview-icon" glyph="info-sign" /> Preview
            Fields
          </h4>
          {!this.state.expanded && (
            <Button bsStyle="link" onClick={this.expand}>
              Show
            </Button>
          )}
        </div>
        {this.state.expanded && (
          <span>
            <h5>
              <Glyphicon
                className="deprecation-icon"
                glyph="exclamation-sign"
              />
              Preview fields are under development and likely to change. To
              request access to a preview field, please contact our Support
              Team.
            </h5>
            <TypeDocumentationTable
              fields={this.props.fields}
              columns={this.state.documentationTableColumns}
              Row={PreviewFieldRow}
            />
          </span>
        )}
      </div>
    );
  }
}

PreviewFields.propTypes = {
  fields: React.PropTypes.array.isRequired,
  isEnum: React.PropTypes.bool.isRequired,
};

function formatPreviewDescription(description) {
  if (!!description) {
    return description.replace('|||\n|-|-:|\n| Preview | true |', '').trim();
  }
  return '';
}

function PreviewFieldRow({ field }) {
  const description = formatPreviewDescription(field.description);

  return (
    <tbody>
      <tr>
        <ParameterCell parameter={field} isHeader />
        <ParameterCell
          parameter={{
            description: description,
          }}
        />
      </tr>
    </tbody>
  );
}

PreviewFieldRow.propTypes = {
  field: React.PropTypes.object.isRequired,
};
