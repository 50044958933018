class ApiServer {
  constructor(fetch, accessToken) {
    this.fetch = fetch;
    this.accessToken = accessToken;
  }

  query(graphQLParams) {
    return this.accessToken.get().then((token) => this.doFetch(token.access_token, graphQLParams));
  }

  doFetch(accessToken, graphQLParams) {
    return this.fetch('/graphql', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `bearer ${accessToken}`,
      },
      body: JSON.stringify(graphQLParams),
    }).then((response) => {
      if (!response.ok) {
        return response.json().then((json) => {
          throw Error(JSON.stringify(json, null, 2));
        }, () => {
          throw Error('Bad response from API server. Please check your connection.');
        });
      }
      return response.json();
    });
  }
}

export default ApiServer;
