import React from 'react';

const FOOTER_LINKS = [
  { label: 'Main Site', to: 'https://www.getadministrate.com' },
  { label: 'System Status', to: 'https://administrate.statuspage.io/' },
  { label: 'Security Overview', to: 'https://www.getadministrate.com/trust/' },
];

export default function Footer({ asList = false }) {
  if (asList) {
    return <ListFooter />;
  }

  return (
    <footer>
      <div>©2023 Administrate Limited. All rights reserved.</div>
      <div>
        {FOOTER_LINKS.map((link) => (
          <FooterLink key={link.label} to={link.to}>
            {link.label}
          </FooterLink>
        )).reduce((acc, curr, index) => {
          const isLast = index === FOOTER_LINKS.length - 1;
          return [...acc, curr, ...(isLast ? [] : [' • '])];
        }, [])}
      </div>
    </footer>
  );
}

Footer.propTypes = {
  asList: React.PropTypes.bool,
};

Footer.defaultProps = {
  asList: false,
};

function ListFooter() {
  return (
    <footer className="list-footer">
      {FOOTER_LINKS.map((link) => (
        <FooterLink key={link.label} to={link.to}>
          {link.label}
        </FooterLink>
      ))}
    </footer>
  );
}

function FooterLink({ to, children }) {
  return <a href={to}>{children}</a>;
}

FooterLink.propTypes = {
  to: React.PropTypes.string.isRequired,
  children: React.PropTypes.node.isRequired,
};
