import React from 'react';
import NotFoundPage from './NotFoundPage';
import { getHtmlByPath } from '../utils/articles';

function loadDoc(pathName) {
  const docHtml = getHtmlByPath(pathName);

  if (!docHtml) {
    console.warn(`!! Path: ${pathName} did not get any articles`);
    return null;
  }

  return docHtml;
}

function DocumentationContent(props) {
  const articleHtml = loadDoc(props?.location?.pathname || '');
  if (articleHtml == null) {
    return <NotFoundPage />;
  }

  return React.DOM.div({
    dangerouslySetInnerHTML: { __html: articleHtml },
  });
}

DocumentationContent.propTypes = {
  location: React.PropTypes.shape({
    pathname: React.PropTypes.string.isRequired,
  }).isRequired,
};

export default DocumentationContent;
