import React, { Component } from 'react';


class LogOut extends Component {
  render() {
    return <p>Logging Out...</p>;
  }
}

export default LogOut;
