import React from 'react';
import marked from 'marked';
import { Glyphicon, Button } from 'react-bootstrap';
import ParameterCell from './ParameterCell';
import TypeDocumentationTable from './TypeDocumentationTable';

export default class DeprecatedFields extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      documentationTableColumns: [
        { width: 4, label: this.props.isEnum ? 'Values' : 'Fields' },
        { width: 2, label: 'Removal Date' },
        { width: 6, label: 'Deprecation Reason' },
      ],
    };
  }

  expand = () => {
    this.setState(() => ({ expanded: true }));
  };

  render() {
    return (
      <div className="deprecated-fields-section">
        <div>
          <h4>
            <Glyphicon className="deprecation-icon" glyph="exclamation-sign" />{' '}
            Deprecated {this.props.isEnum ? 'Values' : 'Fields'}
          </h4>
          {!this.state.expanded && (
            <Button bsStyle="link" onClick={this.expand}>
              Show
            </Button>
          )}
        </div>
        {this.state.expanded && (
          <TypeDocumentationTable
            fields={this.props.fields}
            columns={this.state.documentationTableColumns}
            Row={DeprecatedFieldRow}
          />
        )}
      </div>
    );
  }
}

DeprecatedFields.propTypes = {
  fields: React.PropTypes.array.isRequired,
  isEnum: React.PropTypes.bool.isRequired,
};

function DeprecatedFieldRow({ field }) {
  const deprecationReason = field.deprecationReason || 'No longer supported';

  const { reason, removalDate } = getDeprecationMetadata(deprecationReason);

  return (
    <tbody>
      <tr>
        <ParameterCell parameter={field} isHeader />
        <ParameterCell
          parameter={{
            description: removalDate,
          }}
        />
        <ParameterCell
          parameter={{
            description: reason,
          }}
        />
      </tr>
    </tbody>
  );
}

DeprecatedFieldRow.propTypes = {
  field: React.PropTypes.object.isRequired,
};

export function getDeprecationMetadata(deprecationReason) {
  const lexer = new marked.Lexer();
  const [token] = lexer.lex(deprecationReason);

  if (!token || token.type !== 'table') {
    return { reason: deprecationReason, removalDate: 'TBD' };
  }

  const [reasonRow, removalDateRow] = token.cells;

  return {
    reason:
      reasonRow && reasonRow.length === 2
        ? reasonRow[1]
        : 'No longer supported',
    removalDate:
      removalDateRow && removalDateRow.length === 2 ? removalDateRow[1] : 'TBD',
  };
}
