export default class HashLinkScroller {
  static hashLinkScroll() {
    const { hash } = window.location;

    if (HashLinkScroller.hasHash(hash)) {
      // Push onto callback queue so it runs after the DOM is updated,
      // this is required when navigating from a different page so that
      // the element is rendered on the page before trying to getElementById.
      setTimeout(() => {
        const element = HashLinkScroller.getElementFromHash(hash);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }

  static hasHash(hash) {
    return hash !== '';
  }

  static getElementFromHash(hash) {
    const id = hash.replace('#', '');
    return document.getElementById(id);
  }
}
