import SearchEngine from './SearchEngine';
import { topLevelSections, walkSection } from './articles';

export default class DocsSearchEngine extends SearchEngine {
  constructor() {
    super();

    for (const section of topLevelSections) {
      walkSection(section, (article) => {
        if (typeof article === 'string') {
          return;
        }

        this.add({
          href: article.path,
          section: 'Docs',
          title: article.title,
          body: article.markdown,
          category: 'article',
        });
      });
    }
  }
}
