import React, { Component } from 'react';
import url from 'url';
import {
  Row,
  Navbar,
  Glyphicon,
  ButtonGroup,
  Button,
  Badge,
} from 'react-bootstrap';
import { browserHistory, Link } from 'react-router';
import { buildClientSchema, parse, print } from 'graphql';
import CopyToClipboard from 'react-copy-to-clipboard';

import { QueryEditor } from '../../node_modules/graphiql/dist/components/QueryEditor';
import { VariableEditor } from '../../node_modules/graphiql/dist/components/VariableEditor';
import { ResultViewer } from '../../node_modules/graphiql/dist/components/ResultViewer';
import CodeMirrorSizer from '../../node_modules/graphiql/dist/utility/CodeMirrorSizer';
import getQueryFacts from '../../node_modules/graphiql/dist/utility/getQueryFacts';
import getSelectedOperationName from '../../node_modules/graphiql/dist/utility/getSelectedOperationName';

import RunButton from './RunButton';

import isomorphicSchemaFetch from '../utils/schemaFetch';
import HashLinkScroller from '../utils/hashLinkScroller';
import { pubSub, apiServer, authServer } from '../utils/services';
import KitAlert from './KitAlert';

export const VIEW_STATE_SPLIT = 'split';
export const VIEW_STATE_FULL = 'full';
export const VIEW_STATE_HIDE = 'hide';

const TOOLBAR_HEIGHT = 50;
const HANDLE_HEIGHT = 37;

const DEFAULT_EDITOR_HEIGHT = 40;
const DEFAULT_RESULTS_HEIGHT = 30;

const WELCOME_QUERY = `# Welcome to the Administrate DX editor.
# This editor allows you to try the Core GraphQL API!
#
# Below you can find two containers: one to set variables for a
# mutation and one to visualise the query results.
#
# On the toolbar above, you can find buttons to perform some
# useful functions: resize the editor, copy to clipboard, undo,
# reformat and run the code.
#
# Enjoy!
`;

function getTop(initialElem) {
  let pt = 0;
  let elem = initialElem;
  while (elem.offsetParent) {
    pt += elem.offsetTop;
    elem = elem.offsetParent;
  }
  return pt;
}

class Playground extends Component {
  static propTypes = {
    resizeView: React.PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-typos
    defaultQuery: React.PropTypes.string,
    // eslint-disable-next-line react/no-typos
    viewState: React.PropTypes.string,
    showEditor: React.PropTypes.bool.isRequired,
  };

  static defaultProps = {
    defaultQuery: WELCOME_QUERY,
    viewState: VIEW_STATE_SPLIT,
  };

  constructor(props) {
    super(props);
    this.state = {
      instance: null,
      instanceLoading: true,
      schema: null,
      query: props.defaultQuery,
      operations: [],
      operationName: undefined,
      variables: '',
      response: '',
      responseIsUpToDate: true,
      editorHeight: DEFAULT_EDITOR_HEIGHT,
      resultsHeight: DEFAULT_RESULTS_HEIGHT,
      configuringEditorSize: true,
    };

    this.tryMeCallback = (message) => this.tryMe(message);

    this.runQuery = this.runQuery.bind(this);
  }

  componentDidMount() {
    this.loadLinkedInstances();
    // this handles the showing and hiding of scrollbar on resize
    this.codeMirrorSizer = new CodeMirrorSizer();
    pubSub.subscribe('tryme', this.tryMeCallback);
    isomorphicSchemaFetch('core').then((schemaData) => {
      this.setState({
        schema: buildClientSchema(schemaData),
      });
      HashLinkScroller.hashLinkScroll();
    });
    this.updateQuery(this.state.query);

    // In the Explorer component, we set the column width to 6 for the playground temporarily
    // as otherwise CodeMirror will generate incorrect css until the text in the editor changes
    // significantly. We use configuringEditorSize to hide the toolbar during this first frame of
    // rendering
    this.setState({ configuringEditorSize: false });
  }

  componentDidUpdate() {
    this.codeMirrorSizer.updateSizes([
      this.queryEditorComponent,
      this.variableEditorComponent,
      this.resultComponent,
    ]);
  }

  componentWillUnmount() {
    pubSub.unsubscribe('tryme', this.tryMeCallback);
    pubSub.unsubscribe('login', this.loginCallback);
  }

  tryMe(query) {
    this.updateQuery(query);
    this.props.resizeView(VIEW_STATE_SPLIT);
    if (this.state.editorHeight < DEFAULT_EDITOR_HEIGHT) {
      this.setEditorHeight(DEFAULT_EDITOR_HEIGHT);
    }
  }

  calculateEditorHeight() {
    return `calc(${this.state.editorHeight}% - ${TOOLBAR_HEIGHT}px)`;
  }

  calculateVariablesHeight() {
    const percentageHeight =
      100 - (this.state.editorHeight + this.state.resultsHeight);
    return `calc(${percentageHeight}% - ${HANDLE_HEIGHT}px)`;
  }

  calculateResultsHeight() {
    return `calc(${this.state.resultsHeight}% - ${HANDLE_HEIGHT}px)`;
  }

  calculateMouseOffset(clientY, node) {
    return clientY - getTop(node) + getTop(node.parentNode);
  }

  calculatePlaygroundHeight() {
    return document.getElementById('playground').clientHeight;
  }

  resizeStart(pane, downEvent) {
    const { clientY } = downEvent;
    let { target } = downEvent;

    // if this is called on something that is not a child of a split pane
    // it will terminate the loop at the root node, so no need for additional check
    while (target.className !== 'split-pane-handle') {
      target = target.parentNode;
    }
    // stop mousedown from starting text select mode
    downEvent.preventDefault();

    const mouseOffset = this.calculateMouseOffset(clientY, target);
    const parentHeight = this.calculatePlaygroundHeight();

    let onMouseUp;

    const onMouseMove = (moveEvent) => {
      if (moveEvent.buttons === 0) {
        onMouseUp();
        return;
      }
      let thisHeightPixels = moveEvent.clientY - mouseOffset;
      if (pane === 'results') {
        thisHeightPixels = parentHeight - thisHeightPixels;
      }
      const thisHeightPercent = (thisHeightPixels * 100) / parentHeight;
      this._setPaneHeight(pane, thisHeightPercent);
    };

    onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  }

  setEditorHeight(height) {
    this._setPaneHeight('editor', height);
  }

  setResultsHeight(height) {
    this._setPaneHeight('results', height);
  }

  _setPaneHeight(pane, height) {
    let thisHeightPercent = height;
    let otherHeight =
      pane === 'results' ? this.state.editorHeight : this.state.resultsHeight;
    const parentHeight = this.calculatePlaygroundHeight();

    const handleHeightPercent = (HANDLE_HEIGHT * 100) / parentHeight;
    const toolbarHeightPercent = (TOOLBAR_HEIGHT * 100) / parentHeight;

    const thisBarHeightPercent =
      pane === 'results' ? handleHeightPercent : toolbarHeightPercent;
    const otherBarHeightPercent =
      pane === 'results' ? toolbarHeightPercent : handleHeightPercent;

    // min height must leave enough for HANDLE_BAR height
    const newHeightMin = thisBarHeightPercent;
    // max height must leave enough for TOOLBAR_HEIGHT;
    const newHeightMax = 100 - handleHeightPercent;

    if (thisHeightPercent < newHeightMin) {
      thisHeightPercent = newHeightMin;
    }
    if (thisHeightPercent > newHeightMax - otherHeight) {
      otherHeight = Math.max(
        100 - handleHeightPercent - thisHeightPercent,
        otherBarHeightPercent,
      );
      thisHeightPercent = Math.min(
        thisHeightPercent,
        100 - otherBarHeightPercent - handleHeightPercent,
      );
    }
    if (pane === 'results') {
      this.setState({
        editorHeight: otherHeight,
        resultsHeight: thisHeightPercent,
      });
    } else {
      this.setState({
        editorHeight: thisHeightPercent,
        resultsHeight: otherHeight,
      });
    }
  }

  formatQuery() {
    let query;
    try {
      query = print(parse(this.state.query));
    } catch (error) {
      return;
    }
    if (query[0] === '{') {
      query = `query ${query}`;
    }
    if (query !== this.state.query) {
      this.updateQuery(query);
    }
  }

  undoQueryEdit() {
    this.queryEditorComponent.getCodeMirror().undo();
  }

  updateQuery(value) {
    const queryFacts = this._updateQueryFacts(
      value,
      this.state.operationName,
      this.state.operations,
      this.state.schema,
    );

    this.setState({
      responseIsUpToDate: false,
      query: value,
      queryLink: this._calculateQueryLink(value),
      ...queryFacts,
    });
  }

  _calculateQueryLink(query) {
    const currentUrl = url.parse(window.location.href);
    currentUrl.search = `query=${encodeURIComponent(query)}`;
    return currentUrl.format();
  }

  _updateQueryFacts = (query, operationName, prevOperations, schema) => {
    const queryFacts = getQueryFacts(schema, query);

    if (queryFacts) {
      const updatedOperationName = getSelectedOperationName(
        prevOperations,
        operationName,
        queryFacts.operations,
      );

      return {
        operationName: updatedOperationName,
        ...queryFacts,
      };
    }
    return {};
  };

  updateVariables(value) {
    this.setState({
      variables: value,
    });
  }

  toggleFullscreen() {
    if (this.props.viewState === VIEW_STATE_SPLIT) {
      this.props.resizeView(VIEW_STATE_FULL);
    } else {
      this.props.resizeView(VIEW_STATE_SPLIT);
    }
  }

  runQuery(operationName) {
    try {
      this._executeQuery(operationName);
    } catch (error) {
      this.setState({
        response: error.message,
        responseIsUpToDate: true,
      });
    }
    if (this.state.resultsHeight < DEFAULT_RESULTS_HEIGHT) {
      this.setResultsHeight(DEFAULT_RESULTS_HEIGHT);
    }
  }

  _executeQuery(operationName) {
    const variables = this._validateAndJsonifyVariables();
    this.queryToServer(operationName, variables);
  }

  _validateAndJsonifyVariables() {
    let variables = null;
    try {
      if (this.state.variables && this.state.variables.trim() !== '') {
        variables = JSON.parse(this.state.variables);
      }
    } catch (error) {
      throw new Error(`Variables are invalid JSON: ${error.message}.`);
    }
    if (typeof variables !== 'object') {
      throw new Error('Variables are not a JSON object.');
    }
    return variables;
  }

  queryToServer(operationName, variables) {
    apiServer
      .query({
        query: this.state.query,
        operationName,
        variables: variables ? JSON.stringify(variables) : null,
      })
      .then((response) => {
        this.setState({
          response: JSON.stringify(response, null, 2),
          responseIsUpToDate: true,
        });
      })
      .catch((error) => {
        this.setState({
          response: error.message,
          responseIsUpToDate: true,
        });
      });
  }

  renderHintInformation(elem) {
    const clickCallback = (event) => this._onClickHintInformation(event);
    const removeCallback = () => {
      elem.removeEventListener('DOMNodeRemoved', removeCallback);
      elem.removeEventListener('click', clickCallback);
    };
    elem.addEventListener('click', clickCallback);
    elem.addEventListener('DOMNodeRemoved', removeCallback);
  }

  getWarningElement() {
    if (!this.state.responseIsUpToDate) {
      return (
        <span className="pull-right changes-warning">
          <Glyphicon glyph="alert" /> Changes made since last run. Results out
          of date.
        </span>
      );
    }
    return null;
  }

  _onClickHintInformation(event) {
    if (event.target.className === 'typeName') {
      const typeName = event.target.innerHTML;
      const { schema } = this.state;
      if (schema) {
        const type = schema.getType(typeName);
        if (type) {
          browserHistory.push(`/docs/core/api#${type}`);
        }
      }
    }
  }

  loadLinkedInstances() {
    authServer
      .getLinkedInstances()
      .then(this.onloadLinkedInstancesSuccess.bind(this));
  }

  onloadLinkedInstancesSuccess({ instances }) {
    if (!instances || !instances.length) {
      this.setState({ instance: null, instanceLoading: false });
      return;
    }
    const [{ name, tmsUrl, type }] = instances;
    this.setState({
      instance: { name, tmsUrl, type },
      instanceLoading: false,
    });
  }

  render() {
    const { instance, instanceLoading } = this.state;
    return (
      <Row id="playground" style={{ height: '100%' }}>
        {!this.state.configuringEditorSize && this.props.showEditor && (
          <Navbar id="playground-toolbar" inverse fluid staticTop>
            <Navbar.Header>
              <Navbar.Brand>Editor</Navbar.Brand>
            </Navbar.Header>
            <Navbar.Form pullRight>
              <ButtonGroup>
                <Button
                  title="Minimize"
                  onClick={() => this.props.resizeView(VIEW_STATE_HIDE)}
                >
                  <Glyphicon glyph="minus" />{' '}
                  <span className="visible-lg-inline">Minimize</span>
                </Button>
                <Button
                  title={
                    this.props.viewState === VIEW_STATE_FULL
                      ? 'Split Screen'
                      : 'Full Screen'
                  }
                  onClick={() => this.toggleFullscreen()}
                >
                  <Glyphicon
                    glyph={
                      this.props.viewState === VIEW_STATE_FULL
                        ? 'resize-small'
                        : 'resize-full'
                    }
                  />{' '}
                  <span className="visible-lg-inline">Fullscreen</span>
                </Button>
                <CopyToClipboard text={this.state.queryLink}>
                  <Button title="Copy link to query">
                    <Glyphicon glyph="link" />{' '}
                    <span className="visible-lg-inline">Link</span>
                  </Button>
                </CopyToClipboard>
                <CopyToClipboard text={this.state.query}>
                  <Button title="Copy to Clipboard">
                    <Glyphicon glyph="copy" />{' '}
                    <span className="visible-lg-inline">Copy</span>
                  </Button>
                </CopyToClipboard>
                <Button title="Undo Edit" onClick={() => this.undoQueryEdit()}>
                  <Glyphicon glyph="repeat" />{' '}
                  <span className="visible-lg-inline">Undo</span>
                </Button>
                <Button title="Format Code" onClick={() => this.formatQuery()}>
                  <Glyphicon glyph="align-left" />{' '}
                  <span className="visible-lg-inline">Format</span>
                </Button>
                <RunButton
                  onRun={this.runQuery}
                  operations={this.state.operations}
                />
              </ButtonGroup>
            </Navbar.Form>
            {!instanceLoading && (
              <Navbar.Form pullLeft>
                <div
                  className="editor-instance-link"
                  data-test-id="editor-instance-link"
                >
                  {instance ? (
                    <span>
                      Connected Instance
                      <br />
                      <Badge className="instance-badge">
                        <a href={instance.tmsUrl}>{instance.name}</a>
                      </Badge>
                    </span>
                  ) : (
                    <span>
                      No connection <br />
                      <Link to="/account/profile">Connect your instance</Link>
                    </span>
                  )}
                </div>
              </Navbar.Form>
            )}
          </Navbar>
        )}
        {!instanceLoading && instance && instance.type === 'live' && (
          <KitAlert alertType="danger">
            You are changing data on a LIVE instance!
          </KitAlert>
        )}
        <div
          style={{ height: this.calculateEditorHeight(), overflow: 'hidden' }}
          data-test-id="editor-query"
        >
          <QueryEditor
            ref={(c) => {
              this.queryEditorComponent = c;
            }}
            schema={this.state.schema}
            value={this.state.query}
            onEdit={(value) => this.updateQuery(value)}
            onHintInformationRender={(elem) => this.renderHintInformation(elem)}
          />
        </div>
        <div
          className="split-pane-handle"
          onMouseDown={(event) => this.resizeStart('editor', event)}
        >
          <Glyphicon className="pull-right" glyph="option-vertical" />
          <Glyphicon className="pull-right" glyph="option-vertical" />
          Variables
        </div>
        <div
          style={{
            height: this.calculateVariablesHeight(),
            overflow: 'hidden',
          }}
        >
          <VariableEditor
            ref={(c) => {
              this.variableEditorComponent = c;
            }}
            value={this.state.variables}
            onEdit={(value) => this.updateVariables(value)}
          />
        </div>
        <div
          className="split-pane-handle"
          onMouseDown={(event) => this.resizeStart('results', event)}
        >
          <Glyphicon className="pull-right" glyph="option-vertical" />
          <Glyphicon className="pull-right" glyph="option-vertical" />
          Results
          {this.getWarningElement()}
        </div>
        <div
          style={{ height: this.calculateResultsHeight(), overflow: 'hidden' }}
          data-test-id="editor-result"
        >
          <ResultViewer
            ref={(c) => {
              this.resultComponent = c;
            }}
            value={this.state.response}
          />
        </div>
      </Row>
    );
  }
}

export default Playground;
