import * as Sentry from '@sentry/react';
import AdministrateError from './AdministrateError';

const INSTANCE_CONFIG_ROUTE = '/beta/api/config';
const augmentWithInstanceType = (instances) => (
  instances.map(async ({ tms_url: tmsUrl, ...rest }) => {
    let type = 'live';
    try {
      Sentry.addBreadcrumb({
        level: 'info',
        category: 'authServer.augmentWithInstanceType',
        message: `Attempting to determine instance type for ${tmsUrl}`,
      });
      const response = await fetch(`${tmsUrl}${INSTANCE_CONFIG_ROUTE}`);
      type = (await response.json()).instance_type;
      Sentry.addBreadcrumb({
        level: 'info',
        category: 'authServer.augmentWithInstanceType',
        message: `Instance type ${type} retrieved for ${tmsUrl}`,
      });
    } catch (error) {
      Sentry.captureException(error);
    }
    return { ...rest, tmsUrl, type };
  })
);

class AuthError extends AdministrateError {
  constructor(message, code, statusCode) {
    super(message);
    this.code = code;
    this.statusCode = statusCode;
  }
}

class AuthServer {
  constructor(fetch) {
    this.fetch = fetch;
  }

  signUp(email) {
    return this.serverRequest('/signup', 'post', {
      email,
    });
  }

  verifyEmail(key, password, firstName, lastName) {
    return this.serverRequest('/verify', 'post', {
      key, password, first_name: firstName, last_name: lastName,
    });
  }

  verifyEmailChange(key) {
    return this.serverRequest('/verify_email_change', 'post', {
      key,
    });
  }

  requestPasswordReset(email) {
    return this.serverRequest('/request_password_reset', 'post', {
      email,
    });
  }

  resetPassword(token, password) {
    return this.serverRequest('/reset_password', 'post', {
      token, password,
    });
  }

  login(email, password) {
    return this.serverRequest('/login', 'post', {
      email,
      password,
    });
  }

  me() {
    return this.serverRequest('/me', 'get', null);
  }

  logout() {
    return this.serverRequest('/logout', 'delete', null);
  }

  checkVerificationKey(key) {
    return this.serverRequest(
      `/is_verification_key_valid/${key}`,
      'get',
    );
  }

  checkResetPasswordToken(token) {
    return this.serverRequest(
      `/is_password_reset_token_valid/${token}`,
      'get',
    );
  }

  PROFILE_FIELDS = {
    firstName: 'first_name',
    lastName: 'last_name',
    email: 'email',
    pendingEmail: 'pending_email',
    mobileNumber: 'mobile_number',
    companyName: 'company_name',
    password: 'password',
  };

  mapProfileFromServer(profile) {
    const data = {};
    Object.keys(this.PROFILE_FIELDS).forEach((field) => {
      if (this.PROFILE_FIELDS[field] in profile) {
        data[field] = profile[this.PROFILE_FIELDS[field]];
      }
    });
    return data;
  }

  getProfile() {
    return this.serverRequest(
      '/profile',
      'get',
    ).then(({ data }) => this.mapProfileFromServer(data));
  }

  mapProfileToServer(profile) {
    const data = {};
    Object.keys(this.PROFILE_FIELDS).forEach((field) => {
      if (field in profile && field !== 'pendingEmail') {
        data[this.PROFILE_FIELDS[field]] = profile[field];
      }
    });
    return data;
  }

  getLinkedInstances() {
    return this.serverRequest(
      '/linked_instances', 'get',
    ).then(
      ({ data }) => data,
      (error) => {
        if (error instanceof AuthError && error.statusCode === 401) {
          return { instances: [] };
        }
        throw error;
      },
    ).then(
      ({ instances }) => Promise.all(augmentWithInstanceType(instances)),
    ).then(
      (instances) => ({ instances }),
    );
  }

  unlinkInstance(instance) {
    return this.serverRequest(
      '/instance_unlink', 'post', { instance },
    ).then(({ data }) => data);
  }

  updateProfile(profile) {
    return this.serverRequest(
      '/profile',
      'post',
      this.mapProfileToServer(profile),
    ).then(({ data }) => this.mapProfileFromServer(data));
  }

  resendChangeEmailVerification() {
    return this.serverRequest(
      '/resend_change_email_verification',
      'post',
    );
  }

  getAccessToken() {
    return this.serverRequest(
      '/access_token',
      'get',
    );
  }

  createClient(client) {
    return this.serverRequest(
      '/clients',
      'post',
      client,
    );
  }

  getClients() {
    return this.serverRequest(
      '/clients',
      'get',
    );
  }

  updateClient(client) {
    return this.serverRequest(
      `/clients/${client.key}`,
      'put',
      client,
    );
  }

  updateClientSecret(client) {
    return this.serverRequest(
      `/clients/${client.key}/secret`,
      'put',
    );
  }

  getClient(key) {
    return this.serverRequest(
      `/clients/${key}`,
      'get',
    );
  }

  deleteClient(key) {
    return this.serverRequest(
      `/clients/${key}`,
      'delete',
    );
  }

  serverRequest(path, method, body) {
    const requestDict = {
      credentials: 'same-origin',
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    if (method === 'post' || method === 'put') {
      requestDict.body = JSON.stringify(body);
    }

    return this.fetch(path, requestDict).then((response) => response.json().then((json) => {
      if (!response.ok) {
        throw new AuthError(json.error, json.error_code, response.status);
      }
      return json;
    }));
  }
}

export default AuthServer;
export { AuthError };
