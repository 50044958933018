import React, { Component } from 'react';


class DeveloperPage extends Component {
  render() {
    const classNames = `developer-page ${this.props.dpStyle}`;
    return (
      <div className={classNames}>
        <h1>{this.props.title}</h1>
        <div className="col-md-3" />
        <div className="col-md-6">
          {this.props.children}
        </div>
        <div className="col-md-3" />
      </div>
    );
  }
}

DeveloperPage.propTypes = {
  title: React.PropTypes.string.isRequired,
  children: React.PropTypes.node.isRequired,
  // eslint-disable-next-line react/no-typos
  dpStyle: React.PropTypes.string,
};

DeveloperPage.defaultProps = {
  dpStyle: 'dark',
};

export default DeveloperPage;
