import React from 'react';
import { Link, browserHistory } from 'react-router';
import { Glyphicon } from 'react-bootstrap';

import NavigatorCollapsibleItem from './NavigatorCollapsibleItem';


function NavigationItem(props, context) {
  const isExternalLink = props.href.startsWith('http');
  const className = !isExternalLink && context.router.isActive(props.href) ? 'active' : '';
  const glyph = props.glyph ? <Glyphicon glyph={props.glyph} /> : null;
  const target = isExternalLink ? '_blank' : null;
  return (
    <Link className={`list-group-item ${className}`} to={props.href} target={target}>{props.title} {glyph}</Link>
  );
}

NavigationItem.contextTypes = {
  router: React.PropTypes.object,
};

NavigationItem.propTypes = {
  href: React.PropTypes.string.isRequired,
  title: React.PropTypes.string.isRequired,
  glyph: React.PropTypes.string,
};

NavigationItem.defaultProps = {
  glyph: null,
};

export default class NavigationTree extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openItem: null,
      isOpen: props.isOpen,
    };
  }

  componentDidMount() {
    browserHistory.listen(() => {
      this.setState({
        openItem: null,
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      isOpen: nextProps.isOpen,
    });
  }

  setOpen(item) {
    this.setState({
      openItem: item,
    });
  }

  render() {
    const renderedItems = Object.entries(this.props.items).map(([title, item]) => {
      let href = item;
      let glyph;

      if (typeof href === 'string') {
        if (!href.startsWith('http')) {
          href = `/${href}`;
        } else {
          glyph = 'new-window';
        }
        return (<NavigationItem href={href} title={title} glyph={glyph} />);
      } else {
        return (<NavigatorCollapsibleItem
          title={title}
          items={item}
          isOpen={href === this.state.openItem}
          onOpen={() => this.setOpen(href)}
        />);
      }
    });

    const navTreeStyle = this.state.isOpen ? {} : { display: 'none' };
    return <div className="list-group" style={navTreeStyle}>{renderedItems}</div>;
  }
}

NavigationTree.contextTypes = {
  router: React.PropTypes.object,
};

NavigationTree.propTypes = {
  items: React.PropTypes.any.isRequired,
  isOpen: React.PropTypes.bool,
};

NavigationTree.defaultProps = {
  isOpen: false,
};
