import React from 'react';
import NavigationTree from '../NavigationTree';

export default class AccountNav extends React.Component {
  constructor(props) {
    super(props);
    const items = {
      Profile: 'account/profile',
      'My Apps': 'account/my-apps',
    };
    this.state = { items };
  }

  render() {
    return (
      <div className="nav-sidebar">
        <NavigationTree items={this.state.items} isOpen />
      </div>
    );
  }
}
