import React, { Component } from 'react';
import { Router, match, browserHistory } from 'react-router';
import { pathTracker } from '../utils/services';
import HashLinkScroller from '../utils/hashLinkScroller';
import { routes } from '../routes';
import { buildTitleString } from '../utils/pageName';

function updateTitle(_err, _redirect, renderProps) {
  document.title = buildTitleString(window.location.pathname, renderProps.routes);
}

export default class MyRouter extends Component {
  constructor(props) {
    super(props);
    this.handleRouteUpdate = this.handleRouteUpdate.bind(this);
  }

  handleRouteUpdate() {
    pathTracker.updatePath(window.location.pathname);
    HashLinkScroller.hashLinkScroll();

    match({ routes, location: window.location.pathname }, updateTitle);
  }

  render() {
    return (
      <Router
        history={browserHistory}
        routes={routes}
        onUpdate={this.handleRouteUpdate}
      />
    );
  }
}
