import React from 'react';
import ParameterCell from './ParameterCell';

export default function EnumTypeValueRow({ field }) {
  return (
    <tbody>
      <tr>
        <ParameterCell parameter={field} isHeader />
        <ParameterCell parameter={{ description: field.description }} />
      </tr>
    </tbody>
  );
}

EnumTypeValueRow.propTypes = {
  field: React.PropTypes.object.isRequired,
};
