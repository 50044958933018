import React, { Component } from 'react';
import { ProgressBar, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { browserHistory } from 'react-router';
import { authServer, messageStack } from '../../utils/services';
import DeveloperPage from '../DeveloperPage';
import KitAlert from '../../components/KitAlert';


class VerifyEmailChange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      key: this.props.params.key,
      done: false,
    };

    authServer.verifyEmailChange(this.state.key).then(() => {
      authServer.me().then(() => {
        messageStack.push('Email has been verified and updated');
        browserHistory.push('/account/profile');
      }).catch(() => {
        this.setState({
          done: true,
        });
      });
    }).catch((error) => {
      this.setState({
        error: error.message,
      });
    });
  }

  render() {
    return (
      <DeveloperPage dpStyle="dark" title="Verify email change">
        {this.state.error ? <KitAlert alertType="danger" >{this.state.error}</KitAlert> : null}
        {this.getPageContents()}
      </DeveloperPage>
    );
  }

  getPageContents() {
    if (this.state.done === true) {
      return this.showSuccess();
    } else if (this.state.error) {
      return null;
    }
    return this.getWaitingOnVerifyKeyCheck();
  }

  getWaitingOnVerifyKeyCheck() {
    return (
      <div>
        <p className="description">Checking your verification key ...</p>
        <ProgressBar active now={100} />
      </div>
    );
  }

  showSuccess() {
    return (
      <div className="doneMessage">
        <p>
          <span className="glyphicon glyphicon-ok-sign glyph-background" ><span className="inner" /></span>
          Your account has been updated with your new email.
        </p>
        <br />
        <LinkContainer to="/login">
          <Button bsStyle="primary" type="submit" className="loginButton">Login</Button>
        </LinkContainer>
      </div>
    );
  }
}

VerifyEmailChange.propTypes = {
  params: React.PropTypes.object.isRequired,
};

export default VerifyEmailChange;
