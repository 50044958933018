import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router';

import { authServer } from '../../utils/services';

export default class MyApps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apps: [],
    };
  }

  componentDidMount() {
    authServer.getClients().then((clients) => {
      this.setState({ apps: clients.data });
    });
  }

  generateAppsTable() {
    if (this.state.apps && this.state.apps.length > 0) {
      return (
        <Row className="applications-table">
          <Col xs={12}>
            { this.generateAppsRows() }
          </Col>
        </Row>
      );
    }
    return null;
  }

  generateAppsRows() {
    return this.state.apps.map(app => (
      <Row className="application-row">
        <p className="align-middle">{ app.name }</p>
        <Link to={`/account/my-apps/${app.key}`}>
          <Button className="pull-right" bsStyle="outline-primary" type="submit">View Details</Button>
        </Link>
      </Row>
    ));
  }

  render() {
    const appsTable = this.generateAppsTable();
    return (
      <div>
        <Row>
          <Col xs={8}>
            <h1>My Apps</h1>
          </Col>
          <Col xs={4}>
            <Link to="/account/my-apps/new">
              <Button className="header-btn pull-right" bsStyle="primary">Create application</Button>
            </Link>
          </Col>
        </Row>
        {appsTable}
      </div>
    );
  }
}
