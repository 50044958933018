import React from 'react';
import CallToAction from "./CallToAction";

export default function LandingPageDocCard({icon, docClass, title, description, callToAction}) {
    return (
        <div className={`docCard ${docClass}`}>
            <img src={`/images/${icon}.svg`} role="docIcon" />
            <h1>{title}</h1>
            <p>{description}</p>
            {!!callToAction && 
                <CallToAction
                    text={callToAction.text}
                    link={callToAction.link}
                />
            }
        </div>
    );
}

LandingPageDocCard.propTypes = {
    iconClass: React.PropTypes.string.isRequired,
    docClass: React.PropTypes.string.isRequired,
    title: React.PropTypes.string.isRequired,
    description: React.PropTypes.string.isRequired,
    callToAction: React.PropTypes.object
};
