import React from 'react';
import { Button } from 'react-bootstrap';

export default function CallToAction({text, link, className}) {
    return (
        <Button className={`callToAction ${className ? className : ""}`} href={link}>{text}</Button>
    )
}

CallToAction.propTypes = {
    text: React.PropTypes.string.isRequired,
    link: React.PropTypes.string.isRequired,
    className: React.PropTypes.string
};
