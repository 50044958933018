import React, { Component } from 'react';
import {
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
  Button,
} from 'react-bootstrap';
import { browserHistory, Link } from 'react-router';
import {
  developerSession,
  messageStack,
  pathTracker,
} from '../../utils/services';
import DeveloperPage from '../DeveloperPage';
import FormControlPassword from '../forms/FormControlPassword';
import KitAlert from '../../components/KitAlert';

const ACCOUNT_LOCKED_ERROR_CODE = 'account_locked';
const REQUEST_PASSWORD_RESET_ROUTE = '/request-password-reset';

class LogIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      serverError: null,
      infoMessage: messageStack.pop(),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const { target } = event;
    this.setState({
      [target.name]: target.value,
    });
  }

  handleSubmit(event) {
    if (event) {
      event.preventDefault();
    }

    this.setState({
      infoMessage: '',
      serverError: '',
    });

    developerSession
      .login(this.state.email, this.state.password)
      .then(this.handleLogInSuccess.bind(this))
      .catch(this.handleLogInFailure.bind(this));
  }

  handleLogInSuccess() {
    browserHistory.push(pathTracker.getPostLoginPath());
  }

  handleLogInFailure(error) {
    if (error.code === ACCOUNT_LOCKED_ERROR_CODE) {
      messageStack.push(error.message);
      browserHistory.push(REQUEST_PASSWORD_RESET_ROUTE);
    }

    this.setState({
      password: '',
      serverError: error.message,
    });
  }

  render() {
    return (
      <DeveloperPage dpStyle="dark" title="Log in">
        <form onSubmit={this.handleSubmit}>
          {this.state.infoMessage ? (
            <KitAlert alertType="info">{this.state.infoMessage}</KitAlert>
          ) : null}
          {this.state.serverError ? (
            <KitAlert alertType="danger">{this.state.serverError}</KitAlert>
          ) : null}
          <FormGroup controlId="email">
            <ControlLabel>Email</ControlLabel>
            <FormControl
              name="email"
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
              data-test-id="log-in-email-input"
            />
          </FormGroup>
          <FormGroup controlId="password">
            <ControlLabel>Password</ControlLabel>
            <FormControlPassword
              name="password"
              value={this.state.password}
              onChange={this.handleChange}
              testId="log-in-password-input"
            />
            <HelpBlock>
              Forgot your password?{' '}
              <Link to="/request-password-reset">Reset it</Link>
            </HelpBlock>
          </FormGroup>
          <Button bsStyle="primary" type="submit" data-test-id="log-in-submit">
            Log in
          </Button>
        </form>
        <p className="login">
          Don&apos;t have an Administrate DX account?{' '}
          <Link to="/signup">Sign up</Link>
        </p>
      </DeveloperPage>
    );
  }
}

export default LogIn;
export { ACCOUNT_LOCKED_ERROR_CODE };
