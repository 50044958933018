import { browserHistory } from 'react-router';

import PubSub from './pubSub';
import AuthServer from './authServer';
import DeveloperSession from './developerSession';
import PathTracker from './pathTracker';
import ApiAccessToken from './apiAccessToken';
import ApiServer from './apiServer';

const DEVELOPER_SESSION_POLL_TIMEOUT = 30000;

const fetchFunction = fetch.bind(
  typeof window === 'object'
    ? window
    : global,
);

export const pubSub = new PubSub();

export const authServer = new AuthServer(fetchFunction);

export const developerSession = new DeveloperSession(
  DEVELOPER_SESSION_POLL_TIMEOUT,
  authServer,
  pubSub,
  browserHistory,
);

export const messageStack = [];

export const pathTracker = new PathTracker(browserHistory);

export const apiAccessToken = new ApiAccessToken(authServer);

export const apiServer = new ApiServer(fetchFunction, apiAccessToken);
