import React, { Component } from 'react';
import { getSavedState } from '../../utils/oauth';

export const OAUTH_RESPONSE_MESSAGE_TYPE = 'auth-server-popup-response';
const DEFAULT_ERROR_MESSAGE = 'OAuth Error: an error has occurred.';

export default class CallbackPopup extends Component {
  constructor() {
    super();
    this.breadcrumbs = [];
  }

  addBreadcrumb(data) {
    // Must pass Sentry breadcrumbs back to main window that raises the error
    this.breadcrumbs.push({ level: 'info', type: 'debug', ...data });
  }
  render() {
    return (<div>Loading...</div>);
  }

  componentDidMount() {
    this.addBreadcrumb({
      message: 'Instance link callback received',
      data: { url: window.location.href },
    });
    if (!window.opener) {
      throw new Error('No window opener');
    }
    const postMessage = data => (
      window.opener.postMessage({ breadcrumbs: this.breadcrumbs, type: OAUTH_RESPONSE_MESSAGE_TYPE, ...data })
    );
    const payload = this.queryStringToObject();
    const { state, error } = payload;
    this.addBreadcrumb({
      message: 'Instance link callback URL parsed',
      data: { state, error },
    });

    if (error) {
      this.addBreadcrumb({
        level: 'error',
        message: 'Instance link callback error detected',
      });
      postMessage({ error: decodeURI(error) || DEFAULT_ERROR_MESSAGE });
      return;
    }

    if (state && this.checkState(state)) {
      this.addBreadcrumb({
        message: 'Instance link callback state check successful, closing popup',
      });
      postMessage({ payload });
      return;
    }

    this.addBreadcrumb({
      level: 'error',
      message: 'Instance link state mismatch',
    });
    postMessage({ error: 'OAuth error: state mismatch' });
  }

  queryStringToObject() {
    const queryString = window.location.search.split('?')[1];
    const params = new URLSearchParams(queryString);
    return Object.fromEntries(params.entries());
  }

  checkState(receivedState) {
    const expectedState = getSavedState();
    this.addBreadcrumb({
      message: 'Instance link - checking received state matches expected state',
      data: { receivedState, expectedState },
    });
    return expectedState === receivedState;
  }
}
