import React, { Component } from 'react';
import { Modal, Button, Glyphicon } from 'react-bootstrap';

export default class WarningModal extends Component {
  render() {
    return (
      <div>
        <Modal className="warning-modal" bsSize="small" show={this.props.showModal} onHide={this.close}>
          <Modal.Header>
            <Glyphicon glyph="exclamation-sign" />
            <h1>Attention</h1>
          </Modal.Header>
          <Modal.Body>
            {this.props.children}
            <p>What would you like to do?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button bsStyle="outline-primary" onClick={this.props.onClose}>Do nothing</Button>
            <Button bsStyle="outline-danger" onClick={this.props.onSend}>
              {this.props.actionText}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

WarningModal.propTypes = {
  showModal: React.PropTypes.bool.isRequired,
  children: React.PropTypes.string.isRequired,
  onClose: React.PropTypes.func.isRequired,
  onSend: React.PropTypes.func.isRequired,
  actionText: React.PropTypes.func.isRequired,
};
