import React, { Component } from 'react';
import * as Sentry from '@sentry/react';
import { Button, Glyphicon, Modal, ProgressBar } from 'react-bootstrap';
import { apiAccessToken, authServer } from '../../utils/services';
import { getOauthAuthorizeUrl, removeState } from '../../utils/oauth';
import { OAUTH_RESPONSE_MESSAGE_TYPE } from './InstanceLinkCallbackPopup';
import KitAlert from '../KitAlert';


const POPUP_HEIGHT = 700;
const POPUP_WIDTH = 600;
/* eslint-disable react/jsx-no-bind */
export default class Instances extends Component {
  constructor(props) {
    super(props);
    this.boundMessageListener = null;
    this.popupRef = null;
    this.intervalRef = null;
    this.state = {
      showWarningModal: false,
      instances: [],
      loading: true,
      error: null,
    };
  }

  componentDidMount() {
    this.reloadLinkedInstances();
  }

  reloadLinkedInstances() {
    authServer.getLinkedInstances().then(
      this.onGetLinkedInstancesSuccess.bind(this),
    );
  }

  onGetLinkedInstancesSuccess({ instances }) {
    this.setState({
      instances,
      loading: false,
      showWarningModal: false,
    });
  }

  render() {
    return (
      <div className="instance">
        <h1>Instances</h1>
        {this.state.error &&
        <KitAlert alertType="danger">
          There was an error performing the operation:
          <ul>
            <li>{this.state.error.message}</li>
          </ul>
        </KitAlert>
        }
        {this.renderInstances()}
      </div>
    );
  }

  renderInstances() {
    if (this.state.loading) {
      return this.loadingBar();
    }
    if (!this.state.instances.length) {
      return this.renderNoLinkedInstances();
    }
    return this.renderLinkedInstance();
  }

  loadingBar() {
    return (
      <div>
        <p className="description">Loading ...</p>
        <ProgressBar active now={100} />
      </div>
    );
  }

  renderLinkedInstance() {
    const instanceName = this.getInstanceName();
    return (
      <div>
        <p>
          You are linked to: <a href={this.getInstanceUrl()} data-test-id="linked-instance-anchor">{instanceName}</a>
        </p>
        <Button bsStyle="default" onClick={this.showUnlinkWarningModal.bind(this)} data-test-id="unlink-instance-button">Unlink</Button>
        {this.conditionallyRenderUnlinkWarningModal(instanceName)}
      </div>
    );
  }

  unlinkInstance(instance) {
    authServer.unlinkInstance(instance).then(
      apiAccessToken.clearCurrentToken.bind(apiAccessToken),
    ).then(
      this.reloadLinkedInstances.bind(this),
    );
  }

  showUnlinkWarningModal() {
    this.setState({ showWarningModal: true });
  }

  hideUnlinkWarningModal() {
    this.setState({ showWarningModal: false });
  }

  conditionallyRenderUnlinkWarningModal(instance) {
    return this.state.showWarningModal ?
      (
        <div>
          <Modal className="warning-modal" bsSize="small" show={this.state.showWarningModal} onHide={this.hideUnlinkWarningModal.bind(this)}>
            <Modal.Header>
              <Glyphicon glyph="warning-sign" />
              <h1>Unlink Instance</h1>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to continue? You can reconnect to this instance later.
            </Modal.Body>
            <Modal.Footer>
              <Button bsStyle="default" onClick={this.hideUnlinkWarningModal.bind(this)}>Cancel</Button>
              <Button bsStyle="primary" onClick={() => this.unlinkInstance(instance)} data-test-id="unlink-instance-ok-button">OK</Button>
            </Modal.Footer>
          </Modal>
        </div>)
      : null;
  }

  getInstanceName() {
    return this.state.instances[0].name;
  }

  getInstanceUrl() {
    return this.state.instances[0].tmsUrl;
  }

  renderNoLinkedInstances() {
    return (
      <div>
        <p>You are not linked to any instances.</p>
        <Button bsStyle="primary" type="submit" onClick={this.linkInstance.bind(this)} data-test-id="link-instance-button">Link an instance</Button>
      </div>
    );
  }

  linkInstance() {
    this.resetError();
    const url = getOauthAuthorizeUrl();
    this.popupRef = this.openPopup(url);

    const popupMessageListener = async function popupMessageListener(message) {
      try {
        const messageData = message && message.data ? message.data : {};
        if (messageData.type !== OAUTH_RESPONSE_MESSAGE_TYPE) {
          return;
        }
        const { error, payload, breadcrumbs } = messageData;
        breadcrumbs.forEach(Sentry.addBreadcrumb);
        if (error) {
          this.handleCallbackError(error);
          this.closePopup(this.intervalRef, this.popupRef);
          return;
        }
        const { code } = payload;
        await this.completeInstanceLink(code);
        this.closePopup(this.intervalRef, this.popupRef);
      } catch (error) {
        this.handleCallbackError(error);
        this.closePopup(this.intervalRef, this.popupRef);
      } finally {
        removeState();
      }
    };
    const listener = popupMessageListener.bind(this);
    this.boundMessageListener = listener;
    window.addEventListener('message', listener);

    this.intervalRef = setInterval(() => {
      const userClosedPopupBeforeCompletion = !this.popupRef
          || !this.popupRef.window
          || this.popupRef.window.closed;
      if (userClosedPopupBeforeCompletion) {
        clearInterval(this.intervalRef);
        removeState();
        window.removeEventListener('message', listener);
      }
    }, 250);
  }

  componentWillUnmount() {
    if (this.popupMessageListener) {
      window.removeEventListener('message', this.popupMessageListener);
    }
    if (this.intervalRef) {
      clearInterval(this.intervalRef);
    }
  }

  openPopup(url) {
    const top = window.outerHeight / 2 + window.screenY - POPUP_HEIGHT / 2;
    const left = window.outerWidth / 2 + window.screenX - POPUP_WIDTH / 2;
    return window.open(
      url,
      'Instance link popup',
      `height=${POPUP_HEIGHT},width=${POPUP_WIDTH},top=${top},left=${left}`,
    );
  }

  closePopup() {
    if (this.intervalRef) {
      clearInterval(this.intervalRef);
    }
    if (this.popupRef) {
      this.popupRef.close();
    }
    removeState();
    window.removeEventListener('message', this.boundMessageListener);
  }

  async completeInstanceLink(code) {
    this.setState({ loading: true });
    const response = await fetch('/api/instance_link', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ authorization_code: code }),
    });
    if (response.status !== 200) {
      this.handleCallbackError('An error occurred while linking the instance to your account');
      return;
    }
    apiAccessToken.clearCurrentToken();
    this.reloadLinkedInstances();
  }

  handleCallbackError(error) {
    Sentry.captureException(error);
    const message = typeof (error) === 'string' ? error : 'An unknown error occurred';
    this.setState({ error: { message }, loading: false });
  }

  resetError() {
    this.setState({ error: null });
  }
}
/* eslint-enable react/jsx-no-bind */
