import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

import Navigator from '../components/Navigator';
import { pubSub } from "../utils/services";

const DATA_PROP_PREFIX = "publish";

class Documentation extends Component {
  handleDocClicks = (evt) => {
    const dataProps = Object.keys(evt.target.dataset);
    for (const dataProp of dataProps) {
      if (!dataProp.startsWith(DATA_PROP_PREFIX)) {
        continue;
      }

      const channel = dataProp.substring(DATA_PROP_PREFIX.length).toLowerCase();
      const message = evt.target.dataset[dataProp];
      pubSub.publish(channel, message);
    }
  }

  render = () => (
    <Row id="documentation">
      <div className="clearfix content-scroller">
        <Col id="documentation-navigation" lg={4} xsHidden smHidden mdHidden>
          <Navigator
            showArticles={this.props.route.showArticles}
            docsSection={this.props.params.docsSection || this.props.route.docsSection}
          />
        </Col>
        <Col id="documentation-content" lg={8} onClick={this.handleDocClicks}>
          {this.props.children}
        </Col>
      </div>
    </Row>);
}

Documentation.propTypes = {
  children: React.PropTypes.oneOfType([
    React.PropTypes.arrayOf(React.PropTypes.node),
    React.PropTypes.node,
  ]).isRequired,
  route: React.PropTypes.shape({
    showArticles: React.PropTypes.bool.isRequired,
  }).isRequired,
};

export default Documentation;
