import React, { Component } from 'react';
import { Link } from 'react-router';

import { MenuItem, Nav, Navbar, NavDropdown, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import Search from '../Search';
import LoginSignupButtons from '../LoginSignupButtons';
import { withBreakpoints } from 'react-breakpoints';
import Footer from './Footer';
import { getParentSubSection, walkSection } from '../../utils/articles';

const MOBILE_TITLE_OVERRIDES = {
  core: 'Core',
  lms: 'LMS',
  weblink: 'WebLink',
  legacy: 'Legacy API',
  guides: 'Guides',
};

export function buildDocItems(section) {
  const sectionItems = [];
  const sectionNavItems = {
    label: MOBILE_TITLE_OVERRIDES[section],
    to: `docs/${section}`,
    items: sectionItems
  };
  const dropdownLookup = {};

  walkSection(section, (item) => {
    if (typeof item === "string") {
      // we have a dropdown
      const dropDown = {
        label: item,
        to: `docs/${section}/${item}`,
        items: []
      };
      dropdownLookup[item] = dropDown;
      sectionItems.push(dropDown);
      return;
    }

    // we have an article
    const dropdown = getParentSubSection(item.path);
    if (dropdown) {
      dropdownLookup[dropdown].items.push({
        label: item.title,
        to: '/' + item.path
      });
    } else {
      sectionItems.push({
        label: item.title,
        to: '/' + item.path
      });
    }
  });

  sectionItems.push({
    label: 'API Reference',
    to: `/docs/${section}/api`
  });
  sectionItems.push({
    label: 'Upcoming API Changes',
    to: `/docs/${section}/deprecation-tracker`
  });

  return sectionNavItems;
}

export function buildMobileNavbar() {
  return [
    buildDocItems('core'),
    buildDocItems('lms'),
    buildDocItems('weblink'),
    buildDocItems('legacy'),
    COMMON_NAVBAR_ITEMS[3],
    COMMON_NAVBAR_ITEMS[4]
  ];
}

const COMMON_NAVBAR_ITEMS = [
  { label: 'Core', to: '/docs/core/' },
  { label: 'LMS', to: '/docs/lms/' },
  { label: 'WebLink', to: '/docs/weblink/' },
  {
    label: 'Resources',
    to: '/docs/guides/',
    items: [
      {
        label: 'Introduction to GraphQL',
        to: '/docs/guides/01_intro.md',
      },
      {
        label: 'Authorization',
        to: '/docs/guides/02_authorization.md',
      },
      {
        label: 'Webhooks',
        to: '/docs/guides/03_webhooks.md',
      },
      {
        label: 'Legacy API',
        to: '/docs/legacy/01_legacy_introduction.md',
      },
    ],
  },
  {
    label: 'Blog',
    to: 'https://engineering.getadministrate.com/',
    external: true,
  },
];

const MOBILE_NAVBAR_ITEMS = buildMobileNavbar();

class NavigationBar extends Component {
  constructor(props) {
    super(props);
    this.navBarComponent = null;
  }

  collapse() {
    if (this.navBarComponent) {
      this.navBarComponent.getControlledInstance().handleCollapse();
    }
  }

  render() {
    const { breakpoints, currentBreakpoint } = this.props;

    const showDesktopNavigation = shouldShowDesktopNavigation(
      breakpoints,
      currentBreakpoint,
    );

    return (
      <Navbar
        inverse
        collapseOnSelect
        fluid
        fixedTop
        ref={(c) => {
          this.navBarComponent = c;
        }}
      >
        <Navbar.Header>
          <Navbar.Brand>
            <Link to="/">
              <img
                width="150px"
                src="/images/administrate-dx-logo.svg"
                alt="Administrate DX Logo"
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        {showDesktopNavigation ? (
          <DesktopNavigation
            onAction={() => this.collapse()}
            items={COMMON_NAVBAR_ITEMS}
          />
        ) : (
          <MobileNavigation
            onAction={() => this.collapse()}
            items={MOBILE_NAVBAR_ITEMS}
          />
        )}
      </Navbar>
    );
  }
}

function NavigationMenuItem({ label, to, external, items, onClick }) {
  if (items && items.length) {
    return (
      <NavDropdown title={label}>
        {items.map((subItem) => {
          if (subItem.items && subItem.items.length) {
            return (
              <NavDropdown title={subItem.label} key={subItem.to}>
                {subItem.items.map((subSubItem) => (
                  <LinkContainer
                    to={subSubItem.to}
                    id={subSubItem.to}
                    key={subSubItem.to}
                  >
                    <MenuItem onClick={onClick}>{subSubItem.label}</MenuItem>
                  </LinkContainer>
                ))}
              </NavDropdown>
            );
          }

          return (
            <LinkContainer to={subItem.to} id={subItem.to} key={subItem.to}>
              <MenuItem onClick={onClick}>{subItem.label}</MenuItem>
            </LinkContainer>
          );
        })}
      </NavDropdown>
    );
  }

  if (external) {
    return <NavItem href={to}>{label}</NavItem>;
  }

  return (
    <LinkContainer to={to}>
      <NavItem onClick={onClick}>{label}</NavItem>
    </LinkContainer>
  );
}

NavigationMenuItem.propTypes = {
  label: React.PropTypes.string.isRequired,
  to: React.PropTypes.string.isRequired,
  external: React.PropTypes.bool,
  items: React.PropTypes.array,
  onClick: React.PropTypes.func.isRequired,
};

NavigationMenuItem.defaultProps = {
  external: false,
  items: [],
};

export function MobileNavigation({ onAction, items }) {
  return (
    <Navbar.Collapse timeout={0}>
      <Search collapse={onAction} />
      <LoginSignupButtons isNav />
      <Nav>
        {items.map(({ label, to, external, items: subItems }) => (
          <NavigationMenuItem
            label={label}
            external={external}
            to={to}
            items={subItems}
            onClick={onAction}
          />
        ))}
        <Footer asList />
      </Nav>
    </Navbar.Collapse>
  );
}

MobileNavigation.propTypes = {
  onAction: React.PropTypes.func.isRequired,
  items: React.PropTypes.array.isRequired,
};

function DesktopNavigation({ onAction, items }) {
  return (
    <Navbar.Collapse timeout={0}>
      <Search collapse={onAction} />
      <Nav>
        {items.map(({ label, to, external, items: subItems }) => (
          <NavigationMenuItem
            label={label}
            external={external}
            to={to}
            items={subItems}
            onClick={onAction}
          />
        ))}
      </Nav>
      <LoginSignupButtons isNav />
    </Navbar.Collapse>
  );
}

DesktopNavigation.propTypes = {
  onAction: React.PropTypes.func.isRequired,
  items: React.PropTypes.array.isRequired,
};

function shouldShowDesktopNavigation(breakpoints, currentBreakpoint) {
  return (
    breakpoints &&
    currentBreakpoint &&
    breakpoints[currentBreakpoint] > breakpoints.lg
  );
}

export default withBreakpoints(NavigationBar);
