import React, { Component } from 'react';
import MarkdownDocument from '../MarkdownDocument';

class TermsAndConditions extends Component {
  render() {
    const markdown = `
# **Administrate DX Terms and Conditions**

* * *


## **1. Introduction**

Thanks for using Administrate DX. These Terms are between Administrate (as defined below in Definitions) and the developer who registers to enjoy the services of the platform ("you" or “developer”). Your use of the Services and the Documentation is governed by these Terms which include our [Privacy Policy](https://www.getadministrate.com/privacy/). In addition, if you already have a full Administrate instance or Administrate sandbox environment configured, you may also be subject to the terms and Conditions of your existing Agreement with Administrate. These Terms, combined with the documents mentioned above, govern your access to and use of our APIs, app keys and access tokens, and developer webpages and documentation (“Documentation”) (collectively, “Administrate DX”). Administrate DX is designed to allow you to connect your new and existing applications, products and services (“Your Applications”) with Administrate’s own applications, products and services (collectively, the “Administrate Services”).

* * *


## **2. Definitions**

* **Administrate**: means Administrate Limited a company registered in Scotland with company number SC333823 with registered address at 61 Dublin Street, Edinburgh, EH3 6NL.

* **Applications**: any program written using the Administrate API which is provided to any Administrate customer either as part of a stand alone agreement or as an application marketed to all Administrate customers.

* **Authorised Users**: your employees, agents, independent contractors, customers, customer’s customers and any other third party who are authorised by you to use the Services, the Documentation, and Administrate DX.

* **Confidential Information**: information that is proprietary or confidential and is either clearly labelled as such or identified as Confidential Information in clause 8.

* **Documentation**: the document made available to you online via Administrate DX or such other web address notified to you from time to time which sets out a description of the Services and the user instructions for the Services.

* **Services**: the services provided by Administrate to you via Administrate DX or any other website notified to you from time to time, as more particularly described in the Documentation.

* **Software**: the online software applications provided by Administrate as part of the Services.

* **Terms**: the terms and conditions set out in this document.

* **User Data**: data received directly or indirectly from your Authorised Users as required to run your Applications.

* **Virus**: any thing or device (including any software, code, file or programme) which may: prevent, impair or otherwise adversely affect the operation of any computer software, hardware or network, any telecommunications service, equipment or network or any other service or device; prevent, impair or otherwise adversely affect access to or the operation of any programme or data, including the reliability of any programme or data (whether by re-arranging, altering or erasing the programme or data in whole or part or otherwise); or adversely affect the user experience, including worms, trojan horses, viruses and other similar things or devices.

* **Website**: [www.getadministrate.com](http://www.getadministrate.com/)

* **Your Data**: the data inputted by you, Authorised Users, or Administrate on your behalf for the purpose of using the Services or facilitating your use of the Services.

* * *


## **3. Your Use Rights**

Subject to these Terms, you may use Administrate DX solely to enable your Applications to access or interface with the Administrate Service. Your use must be as permitted in our Documentation and is subject to call, usage and other limits which may be described in the Documentation (as may be modified from time to time, and which are incorporated into these Terms) or as we otherwise notify you. All of your rights are non-assignable, non-transferrable, and are not sub-licenseable.

Your use within these Use Rights are permitted "free-of-charge" up to, but not to exceed, the Administrate API limits and Administrate Customer limits set forth above and in associated documentation. Administrate reserves the rights to modify or amend this policy, in its sole discretion, at any time

* * *


## **4. Support and Modification**

While we may provide you with support or modifications for Administrate DX, we are not obligated to do so and have no obligation to fix or respond to errors you may encounter. In our discretion and without liability to you, we may add, remove or modify any features of Administrate DX; impose additional eligibility requirements or restrictions for access to Administrate DX; or discontinue Administrate DX. If we modify Administrate DX, we may require you to use the modified version, which may require updates to  Your Applications developed using previous versions. We typically make these changes as part of our overall Administrate DX program and may not be able to provide you with individual notice of the changes. The sandbox environment is not a production Administrate environment and there are no guarantees of its availability.

* * *


## **5. Your Responsibilities**

Administrate DX is provided to allow customers and developers to enhance their use of the System. You must use Administrate DX in good faith.

You must:

* comply with all applicable laws and regulations;

* obtain and shall maintain all necessary licences, consents, and permissions necessary for us, our contractors and agents to perform our/their obligations under these terms and conditions;

You must not:

* Try to exceed or circumvent limitations on calls and use;

* Copy, reformat, reverse-engineer, or otherwise modify Administrate DX, access credentials, the Website or our content;

* Download, scrape, post or transmit (in any form or means) any part of Administrate DX, Website or our content;

* Sublicense Administrate APIs for use by a third party;

* You will not access, store, distribute or transmit any Viruses, or any material while using the Services that:(a) is unlawful, harmful, threatening, defamatory, obscene, infringing, harassing or racially or ethnically offensive; (b) facilitates illegal activity; (c) depicts sexually explicit images; (d) promotes unlawful violence; (e) is discriminatory based on race, gender, colour, religious belief, sexual orientation, disability; or (f) in a manner that is otherwise illegal or causes damage or injury to any person or property; and we have the right, without affecting our other rights, to disable your access to any material that breaches the above.

* Defame, abuse, harass, stalk or threaten others, promote unlawful activities or send disruptive or offensive messages or advertisements; or

* Access Administrate DX for competitive purposes (including to connect to a competitive product or to create your own competitive product) or publicly disseminate performance information or analysis (including uptime, response time and/or benchmarks) relating to the Administrate APIs.

### **Your Representations and Indemnity**

You are solely responsible for your use of Administrate DX, Your Applications and any data or content that you use with Administrate DX. You represent and warrant that (a) you have full power and authority to enter into and perform these Terms; (b) your use of Administrate DX and Your Applications will not violate any third party rights (including intellectual property rights and rights of privacy or publicity) or any laws, rules, regulations or orders, including those relating to data privacy, data transfer, international communications and the export of technical or personal data ("Laws"); (c) all information you provide to Administrate is and will be true, accurate, and complete; and (d) you will not interfere with Administrate’s business practices, the way in which it offers the Administrate Service or Administrate DX or any third party products or networks used with Administrate DX.  You shall indemnify us against claims, actions, proceedings, losses, damages, expenses and costs (including without limitation court costs and reasonable legal fees) arising out of or in connection with your use of the Services and/or Documentation, provided that: (a) you are given prompt notice of any such claim; (b) we provide reasonable co-operation to you in the defence and settlement of such claim, at your expense; and (c) you are given sole authority to defend or settle the claim.

* * *


## **6. Limitation of Liabilities**

* This clause sets out our entire financial liability (including any liability for the acts or omissions of our employees, agents and sub-contractors) to you: (a) arising under or in connection with these terms and conditions; (b) in respect of any use made by you of the Services and Documentation or any part of them; and (c) in respect of any representation, statement or tortious act or omission (including negligence) arising under or in connection with these terms and conditions.

* Except as expressly and specifically provided in these terms and conditions: (a) you assume sole responsibility for results obtained from your use of the Services and the Documentation, and for conclusions drawn from such use. We shall have no liability for any damage caused by errors or omissions in any information, instructions or scripts which you provide to us in connection with the Services, or any actions taken by us at your direction; (b) all warranties, representations, conditions and all other terms of any kind whatsoever implied by statute or common law are, to the fullest extent permitted by applicable law, excluded from these terms and conditions; and (c) the Services and the Documentation are provided to you on an "as is" basis.

* Nothing in these terms and conditions excludes our liability for anything which cannot be excluded by law.

* (a) we shall not be liable whether in tort (including for negligence or breach of statutory duty), contract, misrepresentation, restitution or otherwise for any loss of profits, loss of business, depletion of goodwill and/or similar losses or loss or corruption of data or information, or pure economic loss, or for any special, indirect or consequential loss, costs, damages, charges or expenses however arising under these terms and conditions; and (b) our total aggregate liability in contract (including in respect of the indemnity at clause), tort (including negligence or breach of statutory duty), misrepresentation, restitution or otherwise, arising in connection with the performance or contemplated performance of these terms and conditions shall be limited to the greater of £10,000 or the total Fees paid for the User Subscriptions during the 12 months immediately preceding the date on which the claim arose.

* * *


## **7. Intellectual Property Rights and Additional Terms**

### **a. Administrate Independent Development and Patent Issues**



You understand and acknowledge that Administrate may be independently creating (or may receive from third parties) features, applications, content, or other products or services that may be similar to or competitive with Your Application, and nothing in these Terms will be construed as restricting or preventing Administrate from doing so. In addition, in order to allow others to benefit from Administrate DX, you agree not to assert (or assist or encourage anyone in asserting) any patent claims against Administrate (or its users, customers partners or developers, or Administrate’s or their respective successors, assigns) where such patent claim relates to the integration, combination or interface of any applications, products or services with the Administrate Service or our other products or services.

### **b. Administrate Rights**



As between you and us, we own all rights, title, and interest, including all intellectual property rights, in and Administrate DX, Administrate Service, our other products and services, and all related technology, websites and content, and any modifications or derivative works of the foregoing (collectively, the "Administrate Materials"). Except for the limited use right expressly granted to you under these Terms, Administrate does not grant you any right, title, or interest in the Administrate Materials.

* * *


## **8. Confidential Information**

* We may both be given access to Confidential Information belonging to the other. Confidential Information shall not be deemed to include information that: (a) is or becomes publicly known other than through any act or omission of the receiving party; (b) was in the receiving party’s lawful possession before the disclosure; (c) is lawfully disclosed to the receiving party by a third party without restriction on disclosure; (d) is independently developed by the receiving party, which independent development can be shown by written evidence; or (e) is required to be disclosed by law, by any court of competent jurisdiction or by any regulatory or administrative body.

* We shall both hold the other’s Confidential Information in confidence and, unless required by law, not make the other’s Confidential Information available to any third party, or use the other’s Confidential Information for any purpose other than the implementation of these terms and conditions.

* We shall both take all reasonable steps to ensure that the other’s Confidential Information to which we have access is not disclosed or distributed by our employees or agents in violation of these terms and conditions.

* Neither of us shall be responsible for any loss, destruction, alteration or disclosure of Confidential Information caused by any third party.

* You acknowledge that details of the Services, and the results of any performance tests of the Services, constitute our Confidential Information.

* We acknowledge that Your Data is your Confidential Information.

* We may not issue a press release or advertisement or make any other public comment relating to your use of the Services without your prior written consent (such consent not to be unreasonably withheld or delayed).

* * *


## **9. Term and Termination**

These Terms remain in effect until terminated. You may terminate these Terms at any time by ceasing all use of Administrate DX and notifying Administrate. We may terminate these Terms for any reason or any reason upon ten (10) days’ notice to you. In addition, we may suspend or terminate these Terms (or your use of all or any of Administrate DX) immediately if we believe you have violated these Terms, if we believe the use of Your Application with Administrate DX is not in our or our users’ best interests, if we cease to offer Administrate DX or as required by Laws.

Upon termination of these Terms:

* all rights and licenses granted to you will terminate immediately and you must stop using all Administrate Materials (unless you have a separate right to use them under another agreement with Administrate);

* neither party is liable to the other party just because these terms and conditions has been terminated;

* you must permanently delete all Administrate Confidential Information and any other data which you stored pursuant to your use of Administrate DX (other than User Data you have collected) and, at Administrate’s request, you will confirm such destruction;

* * *


## **10. Legals**

* Force majeure – We shall have no liability to you if we are prevented from or delayed in performing our obligations under these terms and conditions, or from carrying on our business, by acts, events, omissions or accidents beyond our reasonable control, including, without limitation, strikes, lock-outs or other industrial disputes (whether involving our workforce or the workforce of any third party), failure of a utility service or transport or telecommunications network, act of God, war, riot, civil commotion, malicious damage, compliance with any law or governmental order, rule, regulation or direction, accident, breakdown of plant or machinery, fire, flood, storm or default of suppliers or sub-contractors, provided that you are notified of such an event and its expected duration.

* Conflict – If there is an inconsistency between any of the provisions in these Terms and any other terms relating to the Services (excluding the Administrate Access Agreement), the provisions in the Administrate Access Agreement will prevail.

* Variation – We may vary these Terms at any time in our sole discretion by giving you reasonable advance notice in writing.

* Waiver – No failure or delay by either one of us to exercise any right or remedy provided under these terms and conditions or by law shall constitute a waiver of that or any other right or remedy, nor shall it prevent or restrict the further exercise of that or any other right or remedy. No single or partial exercise of such right or remedy shall prevent or restrict the further exercise of that or any other right or remedy.

* Rights and remedies – Except as expressly provided in these terms and conditions, the rights and remedies provided under these terms and conditions are in addition to, and not exclusive of, any rights or remedies provided by law.

* Severance – (a) If any provision (or part of a provision) of these terms and conditions are found by any court or administrative body of competent jurisdiction to be invalid, unenforceable or illegal, the other provisions shall remain in force. (b) If any invalid, unenforceable or illegal provision would be valid, enforceable or legal if some part of it were deleted, the provision shall apply with whatever modification is necessary to give effect to the commercial intention of the parties.

* Assignment – (a) You shall not without our prior written consent, assign, transfer, charge, sub-contract or deal in any other manner with all or any of your rights or obligations under these terms and conditions. (b) We may at any time assign, transfer, charge, sub-contract or deal in any other manner with all or any of our rights or obligations under these terms and conditions.

* No partnership or agency – Nothing in these terms and conditions is intended to or shall operate to create a partnership between us, or authorise either one of us to act as agent for the other, and neither one of us shall have the authority to act in the name or on behalf of or otherwise to bind the other in any way (including, but not limited to, the making of any representation or warranty, the assumption of any obligation or liability and the exercise of any right or power).

* Third party rights – these terms and conditions do not confer any rights on any other person or party pursuant to the Contracts (Rights of Third Parties) Act 1999.

* Notices – (a) Any notice required to be given under these terms and conditions shall be in writing and shall be delivered by email, by hand or sent by pre-paid first-class post or recorded delivery post to the other party at its address set out in these terms and conditions, or such other address as may have been notified by that party for such purposes, or sent by fax to the other party’s fax number as set out in these terms and conditions. (b) A notice delivered by hand shall be deemed to have been received when delivered (or if delivery is not in business hours, at 9 am on the first business day following delivery). A correctly addressed notice sent by pre-paid first-class post or recorded delivery post shall be deemed to have been received at the time at which it would have been delivered in the normal course of post. A notice sent by fax shall be deemed to have been received at the time of transmission (as shown by the timed printout obtained by the sender).

* Governing law and jurisdiction – (a) these terms and conditions and any dispute or claim arising out of or in connection with it or its subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the law of Scotland.

* Arbitration – Any dispute or difference arising out of or in connection with this contract, including any question regarding its existence, validity or termination, shall be determined by arbitration according to Arbitration Rules set out in the Arbitration (Scotland) Act 2010 (the "Rules") by one or more arbitrators to be agreed between the parties (or failing agreement within fourteen days after either party has given to the other a written request to concur in the appointment of an arbitrator, by an arbitrator to be appointed by the Scottish Arbitration Centre on the written application of either party). The language to be used in the arbitral proceedings shall be English. The seat of arbitration shall be Edinburgh, Scotland. The procedural law of this place shall apply where the Rules are silent. Each party shall be entitled to seek preliminary injunctive relief or other temporary measures from the courts of competent jurisdiction, provided that any proceedings and decisions as to the merits of the dispute are exclusively governed and resolved by arbitration with this clause.
    `;

    return (
      <div className="content-scroller">
        <MarkdownDocument>{markdown}</MarkdownDocument>
      </div>
    );
  }
}

export default TermsAndConditions;
