import React from 'react';
import { Col, Grid, Row } from 'react-bootstrap';

import LandingPageAPICard from './LandingPageAPICard';
import LandingPageDocCard from './LandingPageDocCard';
import LandingPageHeroCard from './LandingPageHeroCard';
import LandingPageTitleCard from './LandingPageTitleCard';

export default function LandingPage({}) {
  return (
    <div className="landingPage">
      <div className="titleSection">
        <Grid className="landingPageContainer">
          <Row>
            <Col xs={12} sm={7} md={6}>
              <LandingPageTitleCard
                cardTitle="The Administrate Developer Experience"
                heroText="Learning Infrastructure Designed for Developers"
                description="Construct Applications, Integrations, Tooling and Workflows using the same APIs our platform runs on."
              />
            </Col>
            <Col xs={12} sm={5} md={6} className="titleImage">
              <img src="/images/titleImage.png" width="100%" />
            </Col>
          </Row>
        </Grid>
      </div>
      <div className="bodySection">
        <Grid className="landingPageContainer">
          <Row>
            <Col xs={12}>
              <h1 className="landingPage">Get Started With Our APIs</h1>
            </Col>
          </Row>
          <Row bsClass="apiRow">
            <Col xs={12} sm={6} lg={3} >
              <LandingPageAPICard
                apiName="Core"
                description="GraphQL API"
                link="/docs/core"
              />
            </Col>
            <Col xs={12} sm={6} lg={3}>
              <LandingPageAPICard
                apiName="LMS"
                description="GraphQL API"
                link="/docs/lms"
              />
            </Col>
            <Col xs={12} sm={6} smOffset={3} lgOffset={0} lg={3}>
              <LandingPageAPICard
                apiName="WebLink"
                description="GraphQL API"
                link="/docs/weblink"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6} smPush={6} className="developersImage">
              <img src="/images/developers.svg" width="100%" />
            </Col>
            <Col xs={12} sm={6} smPull={6}>
              <LandingPageHeroCard
                cardTitle="Developers"
                title="Introduction to GraphQL"
                byLine="Our API uses GraphQL, a next-generation, self-documenting data query language."
                description="GraphQL makes cross-platform and mobile development simpler, allowing developers to create seamless user experiences for their customers. GraphQL offers more control, allowing you to request exactly the data you need."
                callToAction={{
                  link: '/docs/guides/01_intro.md',
                  text: 'Learn More',
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={4}>
              <LandingPageDocCard
                icon="authorization"
                docClass="normal"
                title="Authorization"
                description="Learn about how to authenticate and authorize to any of the Administrate GraphQL APIs with examples on how to begin running queries and integrating with the system."
                callToAction={{
                  link: '/docs/guides/02_authorization.md',
                  text: 'Read More',
                }}
              />
            </Col>
            <Col xs={12} lg={4}>
              <LandingPageDocCard
                icon="webhooks"
                docClass="normal"
                title="Webhooks"
                description="Webhooks allow you to push information from Administrate's TMS to an external system, useful for integrations that require a near-real-time notification."
                callToAction={{
                  link: '/docs/guides/03_webhooks.md',
                  text: 'Read More',
                }}
              />
            </Col>
            <Col xs={12} lg={4}>
              <LandingPageDocCard
                icon="release-notes"
                docClass="highlighted"
                title="Developer Release Notes"
                description="Read our developer release notes to learn about any features, fixes and deprecation notices for our GraphQL and REST APIs."
                callToAction={{
                  link: 'https://support.getadministrate.com/hc/en-us/sections/360000068748',
                  text: 'View Release Notes',
                }}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    </div>
  );
}
