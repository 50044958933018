import SearchEngine from './SearchEngine';
import isomorphicSchemaFetch from './schemaFetch';

const isMutation = (schemaType) => schemaType.kind === 'OBJECT' && (schemaType.name === 'Mutation' || schemaType.name.endsWith('Mutations'));

const isQuery = (schemaType) => schemaType.kind === 'OBJECT' && schemaType.name === 'Query';

function classify(schemaType) {
  if (isMutation(schemaType)) {
    return 'mutation';
  }

  if (isQuery(schemaType)) {
    return 'query';
  }

  // use type as the fallback for everything not a mutation or query
  return 'type';
}

export default class SchemaSearchEngine extends SearchEngine {
  constructor(schemaType) {
    super();

    isomorphicSchemaFetch(schemaType).then((schemaData) => {
      schemaData.__schema.types.forEach((type) => {
        if (type.name.startsWith('_')) {
          return;
        }

        const fields = (type.fields || []).map((field) => field.name).join(' ');
        this.add({
          href: `/docs/${schemaType}/api#${type.name}`,
          category: schemaType,
          title: type.name,
          type: classify(type),
          body: type.description,
          fields,
        });
      });
    });
  }
}
