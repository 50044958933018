const POST_LOGIN_BLACKLIST_REGEX = /\/(login|logout|signup|(reset-password|verify-email|verify-email-change)\/.*)/;

class PathTracker {
  constructor() {
    this.history = [];
  }

  updatePath(newPath) {
    if (newPath !== null) {
      this.history.push(newPath);
    }
  }

  getLastPathExcluding(excludePath) {
    for (let i = this.history.length; i > 0; i -= 1) {
      if (this.history[i - 1] !== excludePath) {
        return this.history[i - 1];
      }
    }
    return null;
  }

  getPostLoginPath() {
    for (let i = this.history.length; i > 0; i -= 1) {
      if (!this.history[i - 1].match(POST_LOGIN_BLACKLIST_REGEX)) {
        return this.history[i - 1];
      }
    }
    return '/';
  }
}

export default PathTracker;
