import React from 'react';

import {
  Glyphicon,
  Button,
  ButtonToolbar,
  DropdownButton,
  MenuItem,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';

const CHARACTER_SPACES_IN_DROP_DOWN_ITEM = 17;

export default class RunButton extends React.Component {
  render() {
    const { operations } = this.props;
    const hasOptions = operations && operations.length > 1;

    if (hasOptions) {
      return this._renderButtonWithOptions();
    }
    return this._renderSimpleRunButton();
  }

  _renderSimpleRunButton() {
    return (
      <Button
        title="Run Query"
        onClick={() => this.props.onRun()}
        data-test-id="query-run-button"
      >
        {this._renderGlyphiconWithRun()}
      </Button>
    );
  }

  _renderButtonWithOptions() {
    return (
      <ButtonToolbar>
        <DropdownButton
          bsStyle="default"
          title={this._renderGlyphiconWithRun()}
          noCaret
          id="dropdown-no-caret"
        >
          {this._renderMenuItems()}
        </DropdownButton>
      </ButtonToolbar>
    );
  }

  _renderGlyphiconWithRun() {
    return (
      <span><Glyphicon glyph="play-circle" /> Run</span>
    );
  }

  _renderMenuItems() {
    return this.props.operations.map(
      (operation, index) => {
        const operationName = operation.name ? operation.name.value : '<Unnamed>';

        if (operationName.length > CHARACTER_SPACES_IN_DROP_DOWN_ITEM) {
          return this._generateMenuItemWithTooltip(index, operationName);
        }

        return this._generateMenuItem(index, operationName);
      },
    );
  }

  _generateMenuItemWithTooltip(index, operationName) {
    return (
      <OverlayTrigger placement="top" overlay={this._generateToolTip(operationName)}>
        {this._generateMenuItem(index, operationName)}
      </OverlayTrigger>
    );
  }

  _generateMenuItem(index, operationName) {
    return (
      <MenuItem
        key={operationName}
        eventKey={index.toString()}
        className="playground-dropdown-item"
        onClick={() => this.props.onRun(operationName)}
      >
        {operationName}
      </MenuItem>
    );
  }

  _generateToolTip(operationName) {
    return (
      <Tooltip id="tooltip">
        {operationName}
      </Tooltip>
    );
  }
}

RunButton.propTypes = {
  onRun: React.PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-typos
  operations: React.PropTypes.array,
};

RunButton.defaultProps = {
  operations: [],
};
