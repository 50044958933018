import articles from './articles-data.json';

const { docs } = articles;
const requestPathLookup = articles.lookup;

/** If there's a leading slash remove it
 *
 * @param {string} requestPath
 * @returns {string}
 */
const cleanPath = (requestPath) => decodeURIComponent(requestPath[0] === '/' ? requestPath.substring(1) : requestPath);

export const getArticleByPath = (requestPath) => docs[requestPathLookup[cleanPath(requestPath)]];

export const getHtmlByPath = (requestPath) => getArticleByPath(cleanPath(requestPath))?.html;

export const getTitleByPath = (requestPath) => getArticleByPath(cleanPath(requestPath))?.title;

export const topLevelSections = [
  'core',
  'lms',
  'weblink',
  'legacy',
  'guides',
];

/** Determines the sub section parent of a given path of an item
 *
 * @param {string} requestPath
 * @returns {false | string}
 */
export function getParentSubSection(path) {
  const pieces = cleanPath(path).split('/').splice(1);
  const fileNameOrDropdownName = pieces[1];

  if (fileNameOrDropdownName.includes('.md')) {
    return false;
  }

  return fileNameOrDropdownName;
}

/**
 * @param {'core' | 'lms' | 'weblink' | 'legacy' | 'guides'} sectionName
 * @param {(item: string | object) => void} fn
 */
export function walkSection(sectionName, fn) {
  const topLevelPath = `docs/${sectionName}`;
  const index = requestPathLookup[topLevelPath];

  for (const indexKey of index) {
    if (typeof indexKey === 'string') {
      // name of section, skip
      continue;
    }

    if (Array.isArray(indexKey)) {
      // we only have 2 levels, we don't need to recurse.
      for (const subIndexKey of indexKey) {
        if (typeof subIndexKey === 'string') {
          // name of sub section, pass just a title
          fn(subIndexKey);
        } else {
          // we have an inner item
          fn(docs[subIndexKey]);
        }
      }
    } else {
      // we have a top level item
      fn(docs[indexKey]);
    }
  }
}
