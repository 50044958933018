import React from 'react';
import SchemaDocument from './SchemaDocument/SchemaDocument';

export default function WebLinkSchemaDocument() {
  return (
    <SchemaDocument
      apiName="weblink"
      displayName="WebLink"
      description="This is the complete reference documentation for the types and
    relations in the Administrate WebLink GraphQL API."
    />
  );
}
