import React, { Component } from 'react';
import {
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  Col,
  HelpBlock,
  Row,
  ProgressBar,
} from 'react-bootstrap';
import { browserHistory, Link } from 'react-router';
import { authServer, developerSession } from '../../utils/services';
import { isValidString, isValidPassword } from '../../utils/validation';
import DeveloperPage from '../DeveloperPage';
import ValidationHelpBlock from '../forms/ValidationHelpBlock';
import FormControlPassword from '../forms/FormControlPassword';
import KitAlert from '../../components/KitAlert';

class VerifyEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      errors: {
        firstName: null,
        lastName: null,
        password: null,
      },
      isKeyValid: null,
      key: this.props.params.key,
    };

    authServer
      .checkVerificationKey(this.state.key)
      .then(() => {
        this.setState({
          isKeyValid: true,
        });
      })
      .catch((error) => {
        this.setState({
          isKeyValid: false,
          error: error.message,
        });
      });

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const { target } = event;
    const { errors } = this.state;
    errors[target.name] = null;
    this.setState({
      [target.name]: target.value,
      errors,
    });
  }

  handleSubmit(event) {
    const errors = {};
    errors.firstName = this.validateString(
      this.state.firstName,
      'First name',
      35,
    );
    errors.lastName = this.validateString(this.state.lastName, 'Last name', 35);

    if (!isValidPassword(this.state.password)) {
      errors.password = ['Valid password is required'];
    }

    if (
      errors.password ||
      (errors.firstName && errors.firstName.length) ||
      (errors.lastName && errors.lastName.length)
    ) {
      this.setState({ errors });
      event.preventDefault();
      return;
    }

    event.preventDefault();
    authServer
      .verifyEmail(
        this.state.key,
        this.state.password,
        this.state.firstName,
        this.state.lastName,
      )
      .then(this.handleVerifySuccess.bind(this))
      .catch(this.handleVerifyError.bind(this));
  }

  validateString(string, name, maxLength) {
    const errors = [];
    if (!isValidString(string)) {
      errors.push(`${name} is required`);
    }

    if (string && string.length > maxLength) {
      errors.push(`${name} must be less than ${maxLength} characters`);
    }
    return errors;
  }

  handleVerifySuccess() {
    developerSession.startPolling();
    browserHistory.push('/');
  }

  handleVerifyError(error) {
    this.setState({
      error: error.message,
    });
  }

  getPageContents() {
    if (this.state.isKeyValid === true) {
      return this.getDetailsForm();
    } else if (this.state.isKeyValid === false) {
      return null;
    }
    return this.getWaitingOnVerifyKeyCheck();
  }

  getWaitingOnVerifyKeyCheck() {
    return (
      <div>
        <p className="description">Checking your verification key ...</p>
        <ProgressBar active now={100} />
      </div>
    );
  }

  getDetailsForm() {
    return (
      <div>
        <p className="description">
          Sign up to Administrate DX where you can start creating your own
          applications with our APIs.
        </p>
        <form onSubmit={this.handleSubmit}>
          <Row>
            <Col
              componentClass={FormGroup}
              sm={6}
              validationState={this.getValidationState('firstName')}
            >
              <ControlLabel for="firstName">First name</ControlLabel>
              <FormControl
                name="firstName"
                type="text"
                value={this.state.firstName}
                onChange={this.handleChange}
              />
              <ValidationHelpBlock errors={this.state.errors.firstName} />
            </Col>
            <Col
              componentClass={FormGroup}
              sm={6}
              validationState={this.getValidationState('lastName')}
            >
              <ControlLabel for="lastName">Last name</ControlLabel>
              <FormControl
                name="lastName"
                type="text"
                value={this.state.lastName}
                onChange={this.handleChange}
              />
              <ValidationHelpBlock errors={this.state.errors.lastName} />
            </Col>
          </Row>
          <FormGroup validationState={this.getValidationState('password')}>
            <ControlLabel for="password">Password</ControlLabel>
            <FormControlPassword
              name="password"
              value={this.state.password}
              onChange={this.handleChange}
            />
            <HelpBlock>
              Must be at least 8 characters and contain 3 of the 4 following
              character sets: lowercase, uppercase, numbers, and symbols.
            </HelpBlock>
          </FormGroup>

          <Button bsStyle="primary" type="submit">
            Create account
          </Button>
          <p className="terms">
            By signing up you agree to our{' '}
            <Link to="/terms-and-conditions">
              Terms and Conditions and Terms of Service
            </Link>
          </p>
        </form>
      </div>
    );
  }

  render() {
    return (
      <DeveloperPage dpStyle="dark" title="Create an account">
        {this.state.error ? (
          <KitAlert alertType="danger">{this.state.error}</KitAlert>
        ) : null}
        {this.getPageContents()}
        <p className="login">
          Already have an Administrate DX account?{' '}
          <Link to="/login">Log in</Link>
        </p>
      </DeveloperPage>
    );
  }

  getValidationState(control) {
    const errors = this.state.errors[control];
    return errors && errors.length ? 'error' : null;
  }
}

VerifyEmail.propTypes = {
  params: React.PropTypes.object.isRequired,
};

export default VerifyEmail;
