import React, { Component } from 'react';
import { Link, browserHistory } from 'react-router';
import { FormGroup, FormControl, ControlLabel, Button } from 'react-bootstrap';
import { isValidEmail } from '../../utils/validation';
import { authServer, messageStack } from '../../utils/services';
import DeveloperPage from '../DeveloperPage';
import ValidationHelpBlock from '../forms/ValidationHelpBlock';
import KitAlert from '../../components/KitAlert';

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errors: {
        email: null,
      },
      serverError: null,
      done: false,
      serverInfo: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const { target } = event;
    const { errors } = this.state;
    errors[target.name] = null;
    this.setState({
      [target.name]: target.value,
      errors,
    });
  }

  handleSubmit(event) {
    if (event) {
      event.preventDefault();
    }

    const errors = {};
    if (!isValidEmail(this.state.email)) {
      errors.email = ['Valid email is required'];
    }

    if (errors.email) {
      this.setState({ errors });
      return;
    }

    authServer
      .signUp(this.state.email)
      .then(
        this.handleSignUpSuccess.bind(this),
        this.handleSignUpFailure.bind(this),
      );
  }

  handleSignUpSuccess(json) {
    const accountStatus = json.data.account_status;
    if (accountStatus === 'signed_up') {
      this.setState({
        done: true,
      });
    } else if (accountStatus === 'not_verified') {
      this.setState({
        serverInfo:
          "You have already signed up. We've resent the verification email.",
      });
    } else if (accountStatus === 'verified') {
      messageStack.push(
        'An Administrate DX account already exists for this email address, please login or reset your password.',
      );
      browserHistory.push('/login');
    }
  }

  handleSignUpFailure(error) {
    this.setState({
      serverError: error.message,
    });
  }

  getAlert(style, variable) {
    if (variable) {
      return <KitAlert alertType={style}>{variable}</KitAlert>;
    }
    return '';
  }

  render() {
    return (
      <DeveloperPage dpStyle="dark" title="Create an account">
        <p className="description">
          Sign up to Administrate DX where you can start creating your own
          applications with our APIs.
        </p>
        {this.getFormOrDoneMessage(this.state.done)}
        <p className="terms">
          By signing up you agree to our{' '}
          <Link to="/terms-and-conditions">
            Terms and Conditions and Terms of Service
          </Link>
        </p>
        <p className="login">
          Already have an Administrate DX account?{' '}
          <Link to="/login">Log in</Link>
        </p>
      </DeveloperPage>
    );
  }

  getFormOrDoneMessage(done) {
    if (done) {
      return this.getDoneMessage();
    }
    return this.getForm();
  }

  getForm() {
    return (
      <form onSubmit={this.handleSubmit}>
        {this.getAlert('danger', this.state.serverError)}
        {this.getAlert('info', this.state.serverInfo)}
        <FormGroup
          controlId="email"
          validationState={this.state.errors.email ? 'error' : null}
        >
          <ControlLabel>Email</ControlLabel>
          <FormControl
            name="email"
            type="email"
            value={this.state.email}
            onChange={this.handleChange}
          />
          <ValidationHelpBlock errors={this.state.errors.email} />
        </FormGroup>
        <Button bsStyle="primary" type="submit">
          Create account
        </Button>
      </form>
    );
  }

  getDoneMessage() {
    return (
      <div className="doneMessage">
        <p>
          <span className="glyphicon glyphicon-ok-sign glyph-background">
            <span className="inner" />
          </span>
          Check your email for the account verification link.
        </p>
        <Button bsStyle="outline-secondary" disabled>
          Email sent
        </Button>
      </div>
    );
  }
}

export default SignUp;
