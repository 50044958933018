import React from 'react';
import { buildClientSchema } from 'graphql';

import SchemaSection from './SchemaSection';
import SchemaTableOfContents from './SchemaTableOfContents';
import isomorphicSchemaFetch from '../../utils/schemaFetch';

export default class SchemaDocument extends React.Component {
  constructor() {
    super();
    this.state = {
      schema: null,
    };
  }

  _getSectionNames() {
    const queryAndMutation = ['Query', 'Mutation'];

    const sectionNames = this._getSortedSectionNamesWithoutQueryAndMutation();

    return queryAndMutation.concat(sectionNames);
  }

  _getSortedSectionNamesWithoutQueryAndMutation() {
    const sectionNames = Object.keys(this.state.schema._typeMap);
    const nonWantedNames = ['Query', 'Mutation'];

    nonWantedNames.forEach((name) => {
      const pos = sectionNames.indexOf(name);
      sectionNames.splice(pos, 1);
    });

    sectionNames.sort();
    return sectionNames;
  }

  componentDidMount() {
    isomorphicSchemaFetch(this.props.apiName).then((schemaData) => {
      this.setState({
        schema: buildClientSchema(schemaData),
      });
    });
  }

  render() {
    if (!this.state.schema) {
      return null;
    }

    const sectionNames = this._getSectionNames(this.state.schema._types);
    const sections = sectionNames.map((sectionName) => {
      const section = this.state.schema._typeMap[sectionName];
      return <SchemaSection section={section} />;
    });

    return (
      <div className="schema-document">
        <h1 id="api-top">{this.props.displayName} GraphQL API Reference</h1>
        <p>{this.props.description}</p>
        <SchemaTableOfContents apiName={this.props.apiName} />
        {sections}
      </div>
    );
  }
}

SchemaDocument.propTypes = {
  apiName: React.PropTypes.string.isRequired,
  displayName: React.PropTypes.string.isRequired,
  description: React.PropTypes.string.isRequired,
};
