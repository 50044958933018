import React from 'react';

export default function LandingPageTitleCard({cardTitle, heroText, description}) {   
    return (
        <div className="titleCard">
            <h4>{cardTitle}</h4>
            <h1>{heroText}</h1>
            <p>{description}</p>
        </div>
    );
}

LandingPageTitleCard.propTypes = {
    cardTitle: React.PropTypes.string.isRequired,
    heroText: React.PropTypes.string.isRequired,
    description: React.PropTypes.string.isRequired,
};
