class ApiAccessToken {
  constructor(authServer) {
    this.authServer = authServer;
    this.currentToken = null;
  }

  get() {
    if (this.currentTokenIsValid()) {
      return Promise.resolve(this.currentToken);
    }

    return this.authServer.getAccessToken().then(({ data }) => this.processNewToken(data));
  }

  currentTokenIsValid() {
    return this.currentToken && !this.tokenIsExpired(this.currentToken);
  }

  tokenIsExpired(token) {
    return new Date() > new Date(token.createdAt).setSeconds(
      token.createdAt.getSeconds() + token.expires_in,
    );
  }

  processNewToken(token) {
    this.currentToken = token;
    this.currentToken.createdAt = new Date();
    return this.currentToken;
  }

  clearCurrentToken() {
    this.currentToken = null;
  }
}

export default ApiAccessToken;
