const OAUTH_STATE_KEY = 'oauth-state-key';

const generateState = () => {
  const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let array = new Uint8Array(40);
  window.crypto.getRandomValues(array);
  array = array.map((x: number) => validChars.codePointAt(x % validChars.length));
  return String.fromCharCode.apply(null, array);
};

export const removeState = () => {
  localStorage.removeItem(OAUTH_STATE_KEY);
};

const saveState = (state) => {
  removeState();
  localStorage.setItem(OAUTH_STATE_KEY, state);
};

export const getSavedState = () => localStorage.getItem(OAUTH_STATE_KEY);

export const getOauthAuthorizeUrl = () => {
  const state = generateState();
  saveState(state);
  const serverBaseUrl = window.__AUTH_HOST__;
  const params = new URLSearchParams({
    client_id: window.__INSTANCE_LINK_CLIENT_ID__,
    redirect_uri: `${window.location.origin}/account/instance-callback`,
    response_type: 'code',
    state,
    is_popup: 'True',
  });
  return `https://${serverBaseUrl}/oauth/authorize?${params}`;
};
