import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import ReactBreakpoints from 'react-breakpoints';

import MyRouter from './components/MyRouter';
import createCookieConsentPopup from './cookieConsentInit';

const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};


window.onload = () => {
  createCookieConsentPopup();

  const sentryDsn = window.__SENTRY_DSN__;
  if (sentryDsn) {
    Sentry.init({
      dsn: sentryDsn,
      environment: window.__SENTRY_ENVIRONMENT__,
      release: window.__SENTRY_RELEASE__,
      integrations: [],
      tracesSampleRate: 0.0,
    });
  }
  ReactDOM.render(
    <ReactBreakpoints breakpoints={breakpoints}>
      <MyRouter />
    </ReactBreakpoints>,
    document.getElementById('main'),
  );
};
