import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

import AccountNav from './AccountNav';


export default class Account extends Component {
  static propTypes = {
    children: React.PropTypes.oneOfType([
      React.PropTypes.arrayOf(React.PropTypes.node),
      React.PropTypes.node,
    ]).isRequired,
  };

  render() {
    return (
      <Row id="account">
        <div className="clearfix content-scroller">
          <Col id="account-navigation" md={3} xsHidden smHidden>
            <AccountNav />
          </Col>
          <Col id="account-content" md={9}>
            {this.props.children}
          </Col>
        </div>
      </Row>
    );
  }
}
