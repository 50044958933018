import React from 'react';

export default function TypeDocumentationTable({ fields, columns, Row }) {
  if (!fields || !fields.length) {
    return null;
  }

  const sortedFields = [...fields].sort((a, b) => (a.name > b.name ? 1 : -1));

  return (
    <table className="table api-doc">
      <thead>
        <tr>
          {columns.map(({ label, width }) => (
            <th key={label} className={`col-md-${width}`}>
              {label}
            </th>
          ))}
        </tr>
      </thead>
      {sortedFields.map(field => (
        <Row key={field.name} field={field} />
      ))}
    </table>
  );
}

TypeDocumentationTable.propTypes = {
  fields: React.PropTypes.array.isRequired,
  columns: React.PropTypes.array.isRequired,
  Row: React.PropTypes.elementType.isRequired,
};
