export const EMAIL_REGEX = /(.+)@(.+){2,}\.(.+){2,}/;

export function isValidEmail(email) {
  return !!email && email.length >= 3 && EMAIL_REGEX.test(email);
}

export function countPasswordCharacterGroups(password) {
  let groups = 0;

  if (/[1-9]/.test(password)) {
    groups += 1;
  }

  if (/[a-z]/.test(password)) {
    groups += 1;
  }

  if (/[A-Z]/.test(password)) {
    groups += 1;
  }

  if (/[^A-Za-z0-9]/.test(password)) {
    groups += 1;
  }

  return groups;
}

export function isValidPassword(password) {
  return !!password && password.length >= 8 && countPasswordCharacterGroups(password) >= 3;
}

export function isValidString(value) {
  return !!value && value.length > 0 && value.length < 256;
}
