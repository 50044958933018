import React from 'react';

export default function LandingPageAPICard({apiName, description, link}) {
    return (
        <a href={link}>
            <div className={`apiCard ${apiName.toLowerCase()}`}>
                <div>
                    <img src={`/images/${apiName.toLowerCase()}.svg`} className={`apiLogo ${apiName.toLowerCase()}`} />
                </div>
                <div>
                    <img src={`/images/${apiName.toLowerCase()}-line.svg`} className={`apiSeparator ${apiName.toLowerCase()}`}/>
                </div>
                <div  className={`apiCardText ${apiName.toLowerCase()}`}>
                    <h2 className="apiName">{apiName}</h2>
                    <h3 className="apiDescription">{description}</h3>
                </div>
                <div>
                    <img src="/images/arrow-right.svg" className="apiArrow"/>
                </div>
            </div>
        </a>
    );
}

LandingPageAPICard.propTypes = {
    apiName: React.PropTypes.string.isRequired,
    description: React.PropTypes.string.isRequired,
}
