import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  FormControl,
  ControlLabel,
  FormGroup,
  OverlayTrigger,
  Tooltip,
  Glyphicon,
} from 'react-bootstrap';
import { browserHistory, Link } from 'react-router';
import revalidator from 'revalidator';

import ValidationHelpBlock from '../forms/ValidationHelpBlock';
import { authServer } from '../../utils/services';
import KitAlert from '../../components/KitAlert';
import WarningModal from '../WarningModal';
import { isValidUrl, isValidUrlWithoutQueryParams } from '../../utils/urlMatcher';


export default class ApplicationForm extends Component {
  VALIDATION_SCHEMA = {
    properties: {
      name: {
        name: 'Name',
        type: 'string',
        allowEmpty: false,
        maxLength: 35,
        required: true,
      },
      website_url: {
        name: 'URL',
        type: ['string', 'null'],
        allowEmpty: true,
        maxLength: 255,
        conform(url) {
          return !url ? true : isValidUrl(url);
        },
        required: false,
        messages: {
          conform: 'must be a fully qualified url',
        },
      },
      redirect_uri: {
        name: 'OAuth callback URL',
        type: 'string',
        allowEmpty: false,
        maxLength: 255,
        conform(v) {
          return isValidUrlWithoutQueryParams(v);
        },
        required: true,
        messages: {
          conform: 'must be a fully qualified url without an inline query',
        },
      },
      description: {
        name: 'Description',
        type: ['string', 'null'],
        allowEmpty: true,
        maxLength: 255,
        required: false,
      },
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      client: {},
      errors: {},
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getValidationState = this.getValidationState.bind(this);
    this.openSecretGenerationWarningModal = this.openSecretGenerationWarningModal.bind(this);
    this.onClose = this.onClose.bind(this);
    this.generateNewKey = this.generateNewKey.bind(this);
    this.openDeleteApplicationWarningModal = this.openDeleteApplicationWarningModal.bind(this);
    this.deleteApplication = this.deleteApplication.bind(this);
  }

  componentDidMount() {
    if (this.props.params.key) {
      authServer.getClient(this.props.params.key).then((client) => {
        this.extractClientData(client.data);
      });
    }
  }

  extractClientData(client) {
    this.setState({ client });
  }

  handleChange(event) {
    const { target } = event;
    const { client } = this.state;

    client[target.name] = target.value;

    this.setState({
      client,
    });
  }

  handleSubmit(event) {
    if (event) {
      event.preventDefault();
    }

    this.setState({
      successMessage: null,
      serverError: null,
    });

    this.validateAndSend();
  }

  validateAndSend() {
    this.trimUrls();
    const validation = revalidator.validate(this.state.client, this.VALIDATION_SCHEMA);
    if (!validation.valid) {
      this.handleInvalidFields(validation.errors);
    } else {
      this.setState({ errors: {} });
      this.sendDataToApi();
    }
  }

  trimUrls() {
    const { client } = this.state;
    client.website_url = client.website_url ? client.website_url.trim() : client.website_url;
    client.redirect_uri = client.redirect_uri ? client.redirect_uri.trim() : client.redirect_uri;

    this.setState({ client });
  }

  handleInvalidFields(fieldErrors) {
    const errors = {};
    fieldErrors.forEach((error) => {
      const errorString = `${this.VALIDATION_SCHEMA.properties[error.property].name} ${error.message}`;
      if (!(error.property in errors)) {
        errors[error.property] = [];
      }
      errors[error.property].push(errorString);
    });
    this.setState({ errors });
  }

  sendDataToApi() {
    if (this.state.client.key) {
      authServer.updateClient(this.getClientWithoutSecret()).then(() => {
        this.setState({ successMessage: 'The application was successfully updated.' });
      }).catch(() => {
        this.setState({
          serverError:
          'We were not able to update this application, please try again.',
        });
      });
    } else {
      authServer.createClient(this.state.client).then((client) => {
        this.extractClientData(client.data);
        browserHistory.push(`/account/my-apps/${client.data.key}`);
      }).catch(() => {
        this.setState({
          serverError:
          'We were not able to create this application, please try again.',
        });
      });
    }
  }

  getClientWithoutSecret() {
    const clientData = { ...this.state.client };
    delete clientData.secret;
    return clientData;
  }

  getValidationState(control) {
    const errors = this.state.errors[control];
    return errors && errors.length ? 'error' : null;
  }

  conditionallyRenderClientCredentials(client) {
    return client.key ? (
      <Col className="credentials" xs={12}>
        <hr />
        <Col>
          <h2>API Key</h2>
          <p>{client.key}</p>
        </Col>
        <Col>
          <h2>API Secret</h2>
          <p>{client.secret}</p>
        </Col>
        <Col>
          <Button bsStyle="outline-primary" onClick={this.openSecretGenerationWarningModal}>Generate new secret</Button>
        </Col>
      </Col>
    ) : null;
  }

  conditionallyRenderWarningModal() {
    return this.state.modalParams ?
      (
        <WarningModal
          onSend={this.state.modalParams.onSend}
          onClose={this.onClose}
          showModal={this.state.modalParams.show}
          actionText={this.state.modalParams.actionText}
        >
          {this.state.modalParams.bodyText}
        </WarningModal>
      ) : null;
  }

  conditionallyRenderDeleteButton() {
    return this.state.client.key ? (
      <Button id="delete-app-btn" bsStyle="outline-danger" onClick={this.openDeleteApplicationWarningModal}>
        Delete application
      </Button>
    ) : null;
  }

  openSecretGenerationWarningModal() {
    this.openModal(
      'Generating a new secret will replace the existing secret and ' +
      'revoke access to anyone using these credentials.',
      'Generate new secret',
      this.generateNewKey,
    );
  }

  openModal(bodyText, actionText, onSend) {
    const modalParams = {
      show: true,
      bodyText,
      actionText,
      onSend,
    };

    this.setState({ modalParams });
  }

  generateNewKey() {
    this.setState({ modalParams: null });

    return authServer.updateClientSecret(this.state.client).then((response) => {
      const { client } = this.state;
      client.secret = response.data.secret;
      this.setState({ client });
    }).catch(() => {
      this.setState({
        serverError: 'We were not able generate a new secret, please try again.',
      });
    });
  }


  onClose() {
    this.setState({ modalParams: null });
  }

  openDeleteApplicationWarningModal() {
    this.openModal(
      'Deleting will revoke access from anyone using this app.',
      'Delete',
      this.deleteApplication,
    );
  }

  deleteApplication() {
    return authServer.deleteClient(this.state.client.key).then(() => {
      browserHistory.push('/account/my-apps');
    }).catch((e) => {
      this.handleApplicationDeletionError(e);
    });
  }

  handleApplicationDeletionError(e) {
    if (e.message === 'NotFoundException') {
      browserHistory.push('/account/my-apps');
    } else {
      this.setState({
        serverError: 'A problem occurred while trying to delete the application, please try again.',
        modalParams: null,
      });
    }
  }

  render() {
    const { errors, client } = this.state;
    const submitText = this.state.client.key ? 'Update application' : 'Create application';
    const header = this.state.client.key ? 'Edit my application' : 'Create an application';
    const exampleUrl = 'http://www.example.com';
    const tooltip = (
      <Tooltip id="tooltip">
        This is the URL the user will return to after successfully authenticating the application.
      </Tooltip>
    );
    return (
      <Row id="application-container">
        <Col xs={12}>
          <form id="create-app-form" onSubmit={this.handleSubmit}>
            {this.state.serverError ? <KitAlert className="serverError" alertType="danger">{this.state.serverError}</KitAlert> : null}
            {this.state.successMessage ? <KitAlert className="successMessage" alertType="success">{this.state.successMessage}</KitAlert> : null }
            <Row >
              <Col xs={12}>
                <Link to="/account/my-apps">&lt; My apps</Link>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <h1>{header}</h1>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormGroup validationState={this.getValidationState('name')}>
                  <ControlLabel>Application name</ControlLabel>
                  <FormControl name="name" value={client.name} onChange={this.handleChange} />
                  <ValidationHelpBlock errors={errors.name} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormGroup validationState={this.getValidationState('website_url')}>
                  <ControlLabel>URL of your company or product</ControlLabel>
                  <FormControl
                    name="website_url"
                    value={client.website_url}
                    onChange={this.handleChange}
                    placeholder={exampleUrl}
                  />
                  <ValidationHelpBlock errors={errors.website_url} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormGroup validationState={this.getValidationState('redirect_uri')}>
                  <ControlLabel>OAuth callback URL</ControlLabel>
                  <OverlayTrigger placement="left" overlay={tooltip}>
                    <Glyphicon className="pull-right" glyph="question-sign" />
                  </OverlayTrigger>
                  <FormControl
                    name="redirect_uri"
                    value={client.redirect_uri}
                    onChange={this.handleChange}
                    placeholder={`${exampleUrl}/oauth2Callback`}
                  />
                  <ValidationHelpBlock errors={errors.redirect_uri} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FormGroup validationState={this.getValidationState('description')}>
                  <ControlLabel>Description</ControlLabel>
                  <FormControl componentClass="textarea" value={client.description} name="description" onChange={this.handleChange} />
                  <ValidationHelpBlock errors={errors.description} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Button bsStyle="primary" type="submit">{submitText}</Button>
                {this.conditionallyRenderDeleteButton(client)}
              </Col>
            </Row>
          </form>
        </Col>
        {this.conditionallyRenderClientCredentials(client)}
        {this.conditionallyRenderWarningModal()}
      </Row>
    );
  }
}

ApplicationForm.propTypes = {
  // eslint-disable-next-line react/no-typos
  params: React.PropTypes.object,
};

ApplicationForm.defaultProps = {
  params: {},
};
