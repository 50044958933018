import { Route, browserHistory } from 'react-router';

import { IS_BROWSER } from '../utils/Environment';
import { developerSession } from '../utils/services';


class UnAuthorisedRoute extends Route {
  static defaultProps = {
    onEnter(nextState, replace, callback) {
      developerSession.checkLoggedIn().then((loggedIn) => {
        if (loggedIn) {
          if (IS_BROWSER) {
            browserHistory.push('/');
            return;
          }
        }
        callback();
      });
    },
  };
}

export default UnAuthorisedRoute;
